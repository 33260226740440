import * as R from 'ramda';

import {CommonReactComponent} from "../../../definitions/common.defenitions";
import {SDialog, SDialogProps} from "../../../components/dialog/SDialog";
import SForm from "../../../components/form/form.component";
import STextInput from "../../../components/inputs/text-input/text-input";
import * as yup from "yup";
import {ShutdownEntityT, useShutdowns} from "../../../models/shutdowns/shutdowns-provider";
import SDropdown from "../../../components/inputs/dropdown-input/drop-down-input";
import {useDistricts} from "../../../models/districts/district-provider";
import {useFacilities} from "../../../models/facilities/facilities-provider";
import SCalendarInput from "../../../components/inputs/calendar-input/calendar-input";
import React from "react";
import {createISOString} from "../../../utils/date";
import SSwitcherInput from '../../../components/inputs/switcher-input/switcher-input';
import {PermissionsEnum} from "../../../constants/permissions";
import {useUser} from "../../../models/user/user-provider";

export type ShutdownDialogPropsT = CommonReactComponent & {
  dialogConfig?: SDialogProps;
  entity?: ShutdownEntityT | null;
}

const hours = Array.from({ length: 24 }, (v, i) => ({
  label: i.toString().padStart(2, '0'),
  value: i
}));
const minutes = Array.from({ length: 6 }, (v, i) => ({
  label: (i * 10).toString().padStart(2, '0'),
  value: i * 10
}));

const ShutdownDialog = (props: ShutdownDialogPropsT) => {
  const {
    entity,
    dialogConfig,
  } = props;

  const {
    create,
  } = useShutdowns();
  const {
    data: districtsData,
  } = useDistricts();
  const {
    data: facilitiesData,
  } = useFacilities();
  const {
    rights,
  } = useUser();

  return (
    <SDialog
      canAdd={rights?.includes(PermissionsEnum.SHUTDOWNS_SAVE)}
      buttonLabel={'Новое отключение'}
      dialogTitle={'Отключение'}
      dialogConfig={{
        style: { width: '50vw' },
        ...dialogConfig?.dialogConfig,
      }}
      buttonConfig={{
        ...dialogConfig?.buttonConfig,
      }}
    >
      <SForm
        disabled={!rights?.includes(PermissionsEnum.SHUTDOWNS_SAVE)}
        onSubmit={(entity: ShutdownEntityT) => create(R.omit(['$$fromHr', '$$fromMin', '$$toHr', '$$toMin'], {
          ...entity,
          fromDate: createISOString(entity.fromDate, entity.$$fromHr!, entity.$$fromMin!),
          toDate: createISOString(entity.toDate, entity.$$toHr!, entity.$$toMin!),
        }), () => dialogConfig?.dialogConfig?.onHide)}
        defaultValues={entity ?? {
          name: '',
          published: false,
        }}
        schema={yup.object({
          name: yup.string().required('Наименование обязательно!'),
          districtId: yup.number().required('Район обязателен!'),
          // $$toHr: yup.number().required(),
          // $$toHr: yup.number().required(),
          // $$toMin: yup.number().required(),
        })}
      >
        <SDropdown
          label={'Район'}
          name={'districtId'}
          options={districtsData}
          optionValue={'id'}
          optionLabel={'name'}
        />
        <SDropdown
          label={'Объект эл. сетевого хозяйства'}
          name={'facilityId'}
          options={facilitiesData}
          optionValue={'id'}
          optionLabel={'name'}
        />
        <STextInput
          label={'Адрес'}
          name={'address'}
        />
        <STextInput
          label={'Подробности'}
          name={'description'}
        />
        <div
          className={'flex'}
        >
          <SCalendarInput
            label={'Дата отключения'}
            name={'fromDate'}
          />
          <div
            className={'ml-3'}
          />
          <SDropdown
            label={'Время, часы'}
            name={'$$fromHr'}
            options={hours}
          />
          <div
            className={'ml-3'}
          />
          <SDropdown
            label={'Время, минуты'}
            name={'$$fromMin'}
            options={minutes}
          />
        </div>
        <div
          className={'flex'}
        >
          <SCalendarInput
            label={'Дата предполагаемого включения'}
            name={'toDate'}
          />
          <div
            className={'ml-3'}
          />
          <SDropdown
            label={'Время, часы'}
            name={'$$toHr'}
            options={hours}
          />
          <div
            className={'ml-3'}
          />
          <SDropdown
            label={'Время, минуты'}
            name={'$$toMin'}
            options={minutes}
          />
        </div>
        <SSwitcherInput
          name={'published'}
          label={'Опубликовано'}
        />
      </SForm>
    </SDialog>
  );
}

export default ShutdownDialog;