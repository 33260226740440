import React, {FC, useRef} from 'react';
import {Outlet, useNavigate} from 'react-router-dom';

import {Avatar} from 'primereact/avatar';
import {Menubar} from 'primereact/menubar';
import {TieredMenu} from 'primereact/tieredmenu';
import {MenuItem} from 'primereact/menuitem';

import {CommonReactComponent} from '../definitions/common.defenitions';
import {useUser} from '../models/user/user-provider';
import {NAVIGATOR_MENU_ITEMS} from '../navigation/navigator';
import FilesProvider from '../models/files/files-provider';
import DocumentTypeProvider from '../models/document-type/document-type-provider';

import guardedPage from '../hoc/guarded-page';


const PrivateLayout: FC<CommonReactComponent> = () => {
  const {
    logout,
    rights,
  } = useUser();
  const navigate = useNavigate();
  const avatarMenuRef = useRef<TieredMenu>(null);

  const avatarMenuItems: MenuItem[] = [
    {
      id: '0',
      label: 'Выход',
      icon: 'pi pi-sign-out',
      command: logout,
    }
  ];

  const avatarManuElement: React.ReactNode = (
    <TieredMenu
      ref={avatarMenuRef}
      model={avatarMenuItems}
      popup={true}
      breakpoint={'767px'}
    />
  );

  const endElement: React.ReactNode = (
    <Avatar
      icon={'pi pi-user'}
      shape={'circle'}
      onClick={(e) => avatarMenuRef.current?.toggle(e)}
    />
  );

  return (
    <div
      className={'flex flex-column'}
    >
      <Menubar
        model={NAVIGATOR_MENU_ITEMS({navigate, rights})}
        end={endElement}
      />
      <div
        className={'mt-1'}
      >
        {avatarManuElement}
        <FilesProvider>
          <DocumentTypeProvider>
            <Outlet/>
          </DocumentTypeProvider>
        </FilesProvider>
      </div>
    </div>
  );
}

export default guardedPage(PrivateLayout);
