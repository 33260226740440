import * as R from 'ramda';
import {AxiosError, AxiosResponse} from 'axios';
import React, {createContext, FC, useContext} from 'react';

import axios from './axios';
import {ApiErrorEntity, CommonReactComponent} from '../definitions/common.defenitions';
import {useMessage} from '../providers/message/message-provider';
import {useAuth} from '../providers/auth/auth-provider';
import {SWRConfig} from "swr";
import {fetcher} from "./fetcher";

export type SendRequestT<TProps> = {
  path: string;
  data?: TProps;
  auth?: boolean;
  method?: 'GET' | 'POST' | 'DELETE' | 'PATCH' | 'UPLOAD';
  headers?: Record<string, string>;
  showMessageRequired?: boolean;
}

export type TransportContextT = {
  send: <TProps = any, TResponse = any>(props: SendRequestT<TProps>) => Promise<TResponse>;
}

const context = createContext<TransportContextT>(null!);
const TransportContextProvider = context.Provider;
const useTransport = () => useContext(context);

const TransportProvider: FC<CommonReactComponent> = (props) => {
  const {
    children,
  } = props;
  const {
    showError,
    showMessage,
  } = useMessage();
  const {
    token,
  } = useAuth();

  const send = async <TProps = any, TResponse = any>(cfg: SendRequestT<TProps>): Promise<TResponse> => {
    const {
      auth = true,
      method = 'POST',
      data,
      path,
      headers = {},
      showMessageRequired = true,
    } = cfg;
    let result: AxiosResponse<TResponse>;
    try {
      switch (method) {
        case 'UPLOAD':
          result = await axios.post(
            path,
            data,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
                ...(auth ? { 'Authorization': `Bearer ${token}` } : {}),
                ...headers,
              },
            });
          break;
        case 'POST':
          result = await axios.post(
            path,
            JSON.stringify(data),
            {
              headers: {
                'Content-Type': 'application/json',
                ...(auth ? { 'Authorization': `Bearer ${token}` } : {}),
                ...headers,
              },
            });
          break;
        case 'PATCH':
          result = await axios.patch(
            path,
            JSON.stringify(data),
            {
              headers: {
                'Content-Type': 'application/json',
                ...(auth ? { 'Authorization': `Bearer ${token}` } : {}),
                ...headers,
              },
            });
          break;
        case 'DELETE':
          result = await axios.delete(
            `${path}/${data}`,
            {
              headers: {
                ...(auth ? { 'Authorization': `Bearer ${token}` } : {}),
                ...headers,
              },
            }
          );
          break;
        case 'GET':
        default:
          result = await axios.get(
            path,
            {
              headers: {
                ...(auth ? { 'Authorization': `Bearer ${token}` } : {}),
                ...headers,
              },
              params: data,
            }
          );

          break;
      }
      if (showMessageRequired) showMessage({message: 'Успешно выполнено!'})
      return result?.data;
    } catch (error: any) {
      let message = error?.message ?? 'Неизвестная ошибка! Обратитесь к администратору';
      if (error instanceof AxiosError) {
        const data = error?.response?.data as ApiErrorEntity;
        if (R.isNil(data)) {
          message = error.message;
        }
        message = data.message
      }
      showError({ message });
      return error;
    }
  }

  const ctx = {
    send,
  }
  return (
    <TransportContextProvider
      value={ctx}
    >
      <SWRConfig
        value={{
          fetcher,
          onError: (error, key) => {
            switch (true) {
              case R.isNil(error): {
                showError({message: `Ошибка на стороне сервера. Возможно нет подключения к БД: ${error.message}`});
                break;
              }
              case error?.status === 403: {
                showError({message: `Недостаточно прав для выполнения запроса: ${error.message}`});
                break;
              }
              default: {
                showError({message: `В процессе запроса возникла ошибка: ${error.message}`});
              }
            }
          }
        }}
      >
        {children}
      </SWRConfig>
    </TransportContextProvider>
  );
}

export default TransportProvider;

export {
  TransportProvider,
  useTransport,
}
