import {createContext, FC, useContext} from 'react';
import {CommonEntityProvider, CommonReactComponent} from '../../definitions/common.defenitions';
import {EndpointsEnum} from '../../transport/endpoints';
import {useTransport} from '../../transport/transport-provider';
import {useGet} from '../../transport/useGet';

export type FileEntityT = {
  id?: number;
  name: string;
  url: string;
  publishedDate?: string;
  published?: boolean;
}

export type CreateFileApiRequestT = Omit<FileEntityT, 'id'> & {
}

export type UpdateFileApiRequestT = FileEntityT & {
}

export type FilesContextT = CommonEntityProvider<FileEntityT[]> & {
  create: (entity: CreateFileApiRequestT) => any;
  deleteFile: (id: number) => void;
  upload: (files: File[]) => void;
}

const context = createContext<FilesContextT>(null!);
const FilesContextProvider = context.Provider;
const useFiles = () => useContext(context);

const FilesProvider: FC<CommonReactComponent> = (props) => {
  const {
    children,
  } = props;
  const {
    data,
    isLoading,
  } = useGet<FileEntityT[]>({
    // key: EndpointsEnum.FILE,
    key: null,
  });

  const {
    send,
  } = useTransport();

  const upload = (files: File[]) => {
    const formData = new FormData();
    formData.append('files', files[0]);
    send({
      path: EndpointsEnum.UPLOAD,
      method: 'UPLOAD',
      data: formData,
    });
  }

  const create = (entity: CreateFileApiRequestT) => {
    send<CreateFileApiRequestT>({
      path: EndpointsEnum.FILE,
      data: entity,
    });
  }

  const deleteFile = (id: number) => {
    send({
      path: EndpointsEnum.UPLOAD,
      method: 'DELETE',
      data: id,
    });
  }

  const ctx: FilesContextT = {
    create,
    deleteFile,
    data,
    isLoading,
    upload,
  }

  return (
    <FilesContextProvider
      value={ctx}
    >
      {children}
    </FilesContextProvider>
  );
}

export default FilesProvider;

export {
  FilesProvider,
  useFiles,
}
