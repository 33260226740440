import React, {useEffect, useState} from 'react';

import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog';
import {Button} from 'primereact/button';
import {InputText} from 'primereact/inputtext';

import {useGet} from '../../transport/useGet';
import {FileEntityT, useFiles} from '../../models/files/files-provider';
import {EndpointsEnum} from '../../transport/endpoints';
import UploadDialog from './component/upload-dialog';
import SGrid from '../grid/grid';
import {DataTableSelectionSingleChangeEvent} from 'primereact/datatable';
import {isFunction} from '../../utils/function';
import {useUser} from "../../models/user/user-provider";
import {PermissionsEnum} from "../../constants/permissions";

const DELAY_BEFORE_UPDATE = 500;

export type FileManagerPropsT = {
  selectionMode?: 'multiple' | 'single',
  onChange?: (entity: any) => void;
  value?: any;
}

export const FileManager = (props: FileManagerPropsT) => {
  const {
    // selectionMode = 'single',
    onChange,
    value,
  } = props;
  const [queryFilter, setQueryFilter] = useState<string>();
  const [filters, setFilters] = useState<Object>();
  const {
    data,
    isLoading,
    mutate,
  } = useGet<FileEntityT[]>({
    key: EndpointsEnum.FILE,
    params: filters,
  });
  const {
    deleteFile,
  } = useFiles();
  const {
    rights,
  } = useUser();

  useEffect(() => {
    const getData = setTimeout(() => {
      setFilters({
        query: queryFilter,
      });
    }, DELAY_BEFORE_UPDATE);

    return () => clearTimeout(getData)
  }, [queryFilter]);

  const onQueryFilterChange = (value: string) => {
    setQueryFilter(value);
  }

  const onRemove = (entity: FileEntityT) => {
    confirmDialog({
      message: 'Хотите удалить этот файл? Действие нельзя будет отменить!',
      header: 'Подтвердите удаление',
      acceptLabel: 'Да',
      rejectLabel: 'Отмена',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => deleteFile(entity.id!),
    });
  }

  const headerElement = (
    <div
      className={'flex justify-content-between'}
    >
      <span>
        Файловый менеджер
      </span>
      <div
        className={'flex flex-row'}
      >
        <div
          className={'p-inputgroup flex-1 mr-2'}
        >
          <Button
            icon="pi pi-search"
            onClick={() => mutate()}
          />
          <InputText
            value={queryFilter}
            onChange={(event) => onQueryFilterChange(event.target.value)}
            placeholder="Найти"
          />
          <Button
            icon="pi pi-times"
            onClick={() => setQueryFilter('')}
          />
        </div>
        <UploadDialog
          onSubmit={() => setFilters({ ts: Date.now() })}
        />
      </div>
    </div>
  );

  const onSelectionChange = (event: DataTableSelectionSingleChangeEvent<any>) => {
    if (isFunction(onChange)) {
      onChange(event.value);
    }
  }

  return (
    <>
      <SGrid
        header={headerElement}
        dataKey={'id'}
        selection={value}
        selectionMode={'single'} // TODO!
        onSelectionChange={onSelectionChange}
        columns={[
          // {
          //   selectionMode,
          //   headerStyle: { width: '3rem' },
          // },
          {
            field: 'id',
            header: 'id #',
          },
          {
            field: 'name',
            header: 'Имя файла',
          },
          {
            field: 'url',
            header: 'URL',
          },
          {
            field: 'publishedDate',
            header: 'Дата публикации',
          },
          {
            body: (data, options) => (
              <Button
                disabled={!rights?.includes(PermissionsEnum.UPLOADS_DELETE)}
                icon="pi pi-times"
                onClick={() => onRemove(data)}
                severity="danger"
                outlined
              />
            ),
          },
        ]}
        value={data}
        loading={isLoading}
        paginator={true}
        rows={20}
      />
      <ConfirmDialog/>
    </>
  );
}