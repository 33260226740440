import React, {FC} from 'react';
import {
  Controller,
  ControllerFieldState,
  ControllerRenderProps,
  useFormContext,
  UseFormStateReturn
} from 'react-hook-form';
import clsx from 'clsx';
import {CommonFieldPropsT} from '../definitions/common.defenitions';

export type SFieldProps = CommonFieldPropsT & {
  isFloatingLabel?: boolean;
  render: (cfg: {
    field: ControllerRenderProps,
    fieldState: ControllerFieldState,
    formState: UseFormStateReturn<any>,
  }) => React.ReactElement,
}

const SField: FC<SFieldProps> = (props) => {
  const {
    label,
    name,
    render,
  } = props;
  const {
    control,
    getFieldState,
  } = useFormContext();

  const {
    error,
  } = getFieldState(name);

  return (
    <div
      className="flex flex-column gap-2 mt-3"
    >
      <label
        htmlFor="name"
        className={clsx(
          !!error && 'p-error',
          'text-sm',
        )}
      >
        {label}
      </label>
      <Controller
        control={control}
        name={name}
        render={render}
      />
      {
        !!error && (
          <small
            id={`${name}-help`}
            className={clsx(!!error && 'p-error')}
          >
            {error?.message ?? 'Возникла ошибка!'}
          </small>
        )
      }
    </div>
  );
}

export default SField;
