import {TreeNode} from "primereact/treenode";
import {NullableT} from "../definitions/common.defenitions";

export type EntityWithParentT = {
  id: number;
  parentId?: number;
}

export const entitiesListToTreeNodes = <TEntity extends EntityWithParentT = EntityWithParentT>(list: NullableT<TEntity[]>, entity: TEntity): TreeNode => {
  return {
    data: entity,
    children: list?.filter((itm) => itm.parentId === entity.id).map((itm) => entitiesListToTreeNodes(list, itm)),
  }
}