import axios from './axios';

export type FetcherConfigEntityT = {url: string, token?: string, params?: Object, ver?: string, time?: string};

export const fetcher = <DataT>(cfg: FetcherConfigEntityT) => {
  const {
    url,
    token,
    params,
  } = cfg;

  return axios.get<DataT>(`${url}`, {
    headers: {
      'Authorization': `Bearer ${token}`,
    },
    params,
    paramsSerializer: {
      indexes: null,
    },
  })
    .then(res => res.data)
    .catch((error) => { throw error });
}
