import {IconsEnum} from './icons';

export enum FileExtensionEnum {
  XLS = 'xls',
  XLSX = 'xlsx',
  DOC = 'doc',
  DOCX = 'docx',
  TXT = 'txt',
  PDF = 'pdf',
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
}

export const ICONS_MAP = Object.freeze({
  [FileExtensionEnum.DOC]: IconsEnum.WORD,
  [FileExtensionEnum.PNG]: IconsEnum.IMAGE,
  [FileExtensionEnum.JPG]: IconsEnum.IMAGE,
  [FileExtensionEnum.PDF]: IconsEnum.PDF,
  [FileExtensionEnum.TXT]: IconsEnum.WORD,
  [FileExtensionEnum.XLS]: IconsEnum.EXCEL,
  [FileExtensionEnum.XLSX]: IconsEnum.EXCEL,
  [FileExtensionEnum.JPEG]: IconsEnum.IMAGE,
  [FileExtensionEnum.DOCX]: IconsEnum.WORD,
});
