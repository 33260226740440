import {MenuItem} from 'primereact/menuitem';

import {RoutesEnum} from './routes';
import {NavigateFunction} from 'react-router-dom';
import {PermissionsEnum} from "../constants/permissions";

export type NavigatorMenuItemsContext = {
  navigate: NavigateFunction;
  rights?: string[];
}

export const NAVIGATOR_MENU_ITEMS = ({navigate, rights}: NavigatorMenuItemsContext): MenuItem[] => ([
  {
    id: 'home',
    label: 'Главная',
    icon: 'pi pi-home',
    command: () => navigate(RoutesEnum.HOME),
  },
  {
    id: 'dictionaries',
    label: 'Справочники',
    icon: 'pi pi-book',
    items: [
      {
        id: 'article-types',
        visible: rights?.includes(PermissionsEnum.ARTICLE_TYPES_GET),
        label: 'Типы статей',
        icon: 'pi pi-file',
        command: () => navigate(RoutesEnum.ARTICLE_TYPES),
      },
      {
        id: 'categories',
        visible: rights?.includes(PermissionsEnum.DOCUMENT_TYPES_GET),
        label: 'Разделы документов',
        icon: 'pi pi-file',
        command: () => navigate(RoutesEnum.DOCUMENT_TYPES),
      },
      {
        id: 'districts',
        visible: rights?.includes(PermissionsEnum.DISTRICTS_GET),
        label: 'Районы',
        icon: 'pi pi-file',
        command: () => navigate(RoutesEnum.DISTRICTS),
      },
      {
        id: 'rights',
        visible: rights?.includes(PermissionsEnum.RIGHTS_GET),
        label: 'Права',
        icon: 'pi pi-file',
        command: () => navigate(RoutesEnum.RIGHTS),
      },
      {
        id: 'roles',
        visible: rights?.includes(PermissionsEnum.ROLES_GET),
        label: 'Роли',
        icon: 'pi pi-file',
        command: () => navigate(RoutesEnum.ROLES),
      },
    ],
  },
  {
    id: 'site',
    label: 'Сайт',
    icon: 'pi pi-globe',
    items: [
      {
        id: 'records',
        visible: rights?.includes(PermissionsEnum.RECORDS_GET),
        label: 'Записи',
        icon: 'pi pi-file',
        command: () => navigate(RoutesEnum.RECORDS),
      },
      {
        id: 'articles',
        visible: rights?.includes(PermissionsEnum.ARTICLES_GET),
        label: 'Статьи',
        icon: 'pi pi-file',
        command: () => navigate(RoutesEnum.ARTICLES),
      },
      {
        id: 'pages',
        visible: rights?.includes(PermissionsEnum.PAGES_GET),
        label: 'Страницы сайта',
        icon: 'pi pi-file',
        command: () => navigate(RoutesEnum.PAGES),
      },
      {
        id: 'documents',
        visible: rights?.includes(PermissionsEnum.DOCUMENTS_GET),
        label: 'Документы',
        icon: 'pi pi-file',
        command: () => navigate(RoutesEnum.DOCUMENTS),
      },
    ],
  },
  {
    id: 'organisation',
    label: 'Предприятие',
    icon: 'pi pi-building',
    items: [
      {
        id: 'facilities',
        visible: rights?.includes(PermissionsEnum.FACILITIES_GET),
        label: 'Объекты эл. сетевого хозяйства',
        icon: 'pi pi-objects-column',
        command: () => navigate(RoutesEnum.FACILITIES),
      },
      {
        id: 'employees',
        visible: rights?.includes(PermissionsEnum.USERS_GET),
        label: 'Сотрудники',
        icon: 'pi pi-users',
        command: () => navigate(RoutesEnum.USERS),
      },
    ],
  },
  {
    id: 'files',
    visible: rights?.includes(PermissionsEnum.FILES_GET),
    label: 'Файлы',
    icon: 'pi pi-file-import',
    command: () => navigate(RoutesEnum.FILES),
  },
  {
    id: 'shutdowns',
    visible: rights?.includes(PermissionsEnum.SHUTDOWNS_GET),
    label: 'Отключения',
    icon: 'pi pi-power-off',
    command: () => navigate(RoutesEnum.SHUTDOWNS),
  },
]);
