export enum PermissionsEnum {
  // Пользователи
  USERS_GET = 'users.get',
  USERS_SAVE = 'users.save',
  USERS_DELETE = 'users.delete',

  // Статьи
  ARTICLES_GET = 'articles.get',
  ARTICLES_SAVE = 'articles.save',
  ARTICLES_DELETE = 'articles.delete',

  // Типы статей
  ARTICLE_TYPES_GET = 'article-types.get',
  ARTICLE_TYPES_SAVE = 'article-types.save',
  ARTICLE_TYPES_DELETE = 'article-types.delete',

  // Районы
  DISTRICTS_GET = 'districts.get',
  DISTRICTS_SAVE = 'districts.save',
  DISTRICTS_DELETE = 'districts.delete',

  // Типы документов
  DOCUMENT_TYPES_GET = 'document-types.get',
  DOCUMENT_TYPES_SAVE = 'document-types.save',
  DOCUMENT_TYPES_DELETE = 'document-types.delete',

  // Документы x
  DOCUMENTS_GET = 'documents.get',
  DOCUMENTS_SAVE = 'documents.save',
  DOCUMENTS_DELETE = 'documents.delete',

  // Учреждения x
  FACILITIES_GET = 'facilities.get',
  FACILITIES_SAVE = 'facilities.save',
  FACILITIES_DELETE = 'facilities.delete',

  // Файлы x
  FILES_GET = 'files.get',
  FILES_SAVE = 'files.save',
  FILES_DELETE = 'files.delete',

  // Страницы x
  PAGES_GET = 'pages.get',
  PAGES_SAVE = 'pages.save',
  PAGES_DELETE = 'pages.delete',

  // Записи x
  RECORDS_GET = 'records.get',
  RECORDS_SAVE = 'records.save',
  RECORDS_DELETE = 'records.delete',

  // Права x
  RIGHTS_GET = 'rights.get',
  RIGHTS_SAVE = 'rights.save',
  RIGHTS_DELETE = 'rights.delete',

  // Роли x
  ROLES_GET = 'roles.get',
  ROLES_SAVE = 'roles.save',
  ROLES_DELETE = 'roles.delete',

  // Отключения
  SHUTDOWNS_GET = 'shutdowns.get',
  SHUTDOWNS_SAVE = 'shutdowns.save',
  SHUTDOWNS_DELETE = 'shutdowns.delete',

  // Загрузки
  UPLOADS_GET = 'uploads.get',
  UPLOADS_SAVE = 'uploads.save',
  UPLOADS_DELETE = 'uploads.delete',
}