import React, {FC} from "react";
import {CommonReactComponent} from "../../definitions/common.defenitions";
import ArticleTypesProvider from "../../models/article-types/article-types-provider";
import ArticlesProvider from "../../models/articles/articles-provider";
import {Outlet} from "react-router-dom";

const ArticlesLayout:FC<CommonReactComponent> = ({ children }) => {
  return (
    <ArticleTypesProvider>
      <ArticlesProvider>
        <Outlet/>
       </ArticlesProvider>
     </ArticleTypesProvider>
  );
}

export default ArticlesLayout;