import * as R from "ramda";
import clsx from 'clsx';
import React, {useState} from "react";

import {UndefT} from "../../definitions/common.defenitions";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import SGrid from "../../components/grid/grid";
import {Button} from "primereact/button";
import {EmployeeEntityT, useEmployees} from '../../models/employees/employees-provider';
import EmployeeDialog from './components/employee-dialog';
import {useUser} from "../../models/user/user-provider";
import {PermissionsEnum} from "../../constants/permissions";

const EmployeesPage = () => {
  const [dialogIsVisible, dialogSetIsVisible] = useState(false);
  const [entity, setEntity] = useState<UndefT<EmployeeEntityT>>(null!);
  const {
    isLoading,
    data,
    open,
    deleteEmployee,
  } = useEmployees();
  const {
    rights,
  } = useUser();

  const onRemove = (entity: EmployeeEntityT) => {
    confirmDialog({
      message: 'Хотите удалить пользователя? Действие нельзя будет отменить!',
      header: 'Подтвердите удаление',
      acceptLabel: 'Да',
      rejectLabel: 'Отмена',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => deleteEmployee(entity.id!, onHide),
    });
  }

  const onSelect = async (selected: EmployeeEntityT) => {
    if (R.isNil(selected) || R.isNil(selected.id)) {
      return;
    }

    const entity = await open(selected.id);
    setEntity(entity);
    dialogSetIsVisible(true);
  }

  const onHide = () => {
    setEntity(null);
    dialogSetIsVisible(false);
  }

  const verifiedBodyTemplate = (rowData: EmployeeEntityT) => {
    return <i className={clsx('pi', { 'true-icon pi-check-circle': rowData.active, 'false-icon pi-times-circle': !rowData.active })}></i>;
  };

  const headerElement = (
    <div
      className={'flex justify-content-between'}
    >
      <span>Записи</span>
      <div
        className={'flex'}
      >
        <EmployeeDialog
          entity={entity}
          dialogConfig={{
            dialogConfig: {
              visible: dialogIsVisible,
              onHide,
            },
            buttonConfig: {
              onClick: () => dialogSetIsVisible(true),
            },
          }}
        />
      </div>
    </div>
  );

  return (
    <>
      <SGrid
        header={headerElement}
        selectionMode={'single'}
        value={data}
        paginator={true}
        rows={20}
        loading={isLoading}
        onRowClick={({ data }) => onSelect(data as EmployeeEntityT)}
        columns={[
          {
            field: 'id',
            header: 'id #',
          },
          {
            field: 'name',
            header: 'Имя',
          },
          {
            field: 'login',
            header: 'Логин',
          },
          {
            headerStyle: { width: '150px' },
            field: 'published',
            header: 'Опубликована',
            body: verifiedBodyTemplate,
            align: 'center',
          },
          {
            headerStyle: { width: '100px' },
            body: (data, options) => (
              <Button
                disabled={!rights?.includes(PermissionsEnum.USERS_DELETE)}
                icon="pi pi-times"
                onClick={() => onRemove(data)}
                severity="danger"
                outlined
              />
            ),
          },
        ]}
      />
      <ConfirmDialog/>
    </>
  );
}

export default EmployeesPage;