import {CommonEntityProvider, CommonReactComponent} from "../../definitions/common.defenitions";
import {createContext, FC, useContext, useState} from "react";
import {useTransport} from "../../transport/transport-provider";
import {useGet} from "../../transport/useGet";
import {EndpointsEnum} from "../../transport/endpoints";
import {isFunction} from "../../utils/function";
import {RoleEntityT} from '../roles/roles-provider';

export type EmployeeEntityT = {
  id: number;
  login: string;
  password: string;
  name: string;
  active: boolean;
  roles?: RoleEntityT[];
  roleIds?: number[];
}

export type CreateEmployeeApiRequestT = Partial<EmployeeEntityT>;
export type EmployeesContextT = CommonEntityProvider<EmployeeEntityT[]> & {
  create: (entity: CreateEmployeeApiRequestT, cb?: () => void) => any;
  deleteEmployee: (id: number, cb?: () => void) => void;
  open: (id: number) => Promise<EmployeeEntityT>;
}

const context = createContext<EmployeesContextT>(null!);
const EmployeesContextProvider = context.Provider;
const useEmployees = () => useContext(context);

const EmployeesProvider: FC<CommonReactComponent> = (props) => {
  const {
    children,
  } = props;

  const {
    send,
  } = useTransport();

  const [time, setTime] = useState(Date.now());

  const {
    isLoading,
    data,
  } = useGet<EmployeeEntityT[]>({
    key: EndpointsEnum.USERS,
    params: {
      time,
    }
  });

  const create = async (entity: CreateEmployeeApiRequestT, cb?: () => void) => {
    await send<CreateEmployeeApiRequestT>({
      method: entity.id ? 'PATCH' : 'POST',
      path: entity.id ? [EndpointsEnum.USERS, entity.id].join('/') : EndpointsEnum.USERS,
      data: entity,
    });
    setTime(Date.now());
    if (isFunction(cb)) {
      cb();
    }
  }

  const deleteEmployee = async (id: number, cb?: () => void) => {
    await send({
      path: EndpointsEnum.USERS,
      method: 'DELETE',
      data: id,
    });
    setTime(Date.now());
    if (isFunction(cb)) {
      cb();
    }
  }

  const open = (id: number) => {
    return send<{ id: number }, EmployeeEntityT>({
      method: 'GET',
      path: [EndpointsEnum.USERS, id].join('/'),
      showMessageRequired: false,
    });
  }

  const ctx: EmployeesContextT = {
    create,
    open,
    deleteEmployee,
    data,
    isLoading,
  }

  return (
    <EmployeesContextProvider
      value={ctx}
    >
      {children}
    </EmployeesContextProvider>
  )
}

export default EmployeesProvider;
export {
  EmployeesProvider,
  useEmployees,
}