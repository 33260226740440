import {FC} from 'react';
import {InputText} from 'primereact/inputtext';
import {classNames} from 'primereact/utils';
import {CommonFieldPropsT} from '../../../definitions/common.defenitions';
import SField from '../../field.component';

export type STextInputPropsT = CommonFieldPropsT & {
  type?: 'text' | 'number' | 'password',
};

const STextInput:FC<STextInputPropsT> = (props) => {
  const {
    type = 'text',
  } = props;

  return (
    <SField
      {...props}
      render={({ field, fieldState }) => (
        <InputText
          id={field.name}
          type={type}
          {...field}
          className={classNames({ 'p-invalid': fieldState.invalid })}
        />
      )}
    />
  );
}

export default STextInput;
