import {CommonReactComponent} from "../../../definitions/common.defenitions";
import {SDialog, SDialogProps} from "../../../components/dialog/SDialog";
import {RecordEntityT, useRecords} from "../../../models/records/records-provider";
import SForm from "../../../components/form/form.component";
import STextInput from "../../../components/inputs/text-input/text-input";
import * as yup from "yup";
import {useUser} from "../../../models/user/user-provider";
import {PermissionsEnum} from "../../../constants/permissions";

export type RecordsDialogPropsT = CommonReactComponent & {
  dialogConfig?: SDialogProps;
  entity?: RecordEntityT | null;
}

const RecordsDialog = (props: RecordsDialogPropsT) => {
  const {
    entity,
    dialogConfig,
  } = props;

  const {
    create,
  } = useRecords();
  const {
    rights,
  } = useUser();


  return (
    <SDialog
      canAdd={rights?.includes(PermissionsEnum.RECORDS_SAVE)}
      buttonLabel={'Новая запись'}
      dialogTitle={'Запись'}
      dialogConfig={{
        style: { width: '50vw' },
        ...dialogConfig?.dialogConfig,
      }}
      buttonConfig={{
        ...dialogConfig?.buttonConfig,
      }}
    >
      <SForm
        disabled={!rights?.includes(PermissionsEnum.RECORDS_SAVE)}
        onSubmit={(entity) => create(entity, () => dialogConfig?.dialogConfig?.onHide)}
        defaultValues={entity ?? {
          name: '',
          value: '',
        }}
        schema={yup.object({
          name: yup.string().required('Имя записи обязательно!'),
          value: yup.string().required('Значение записи обязательно!'),
        })}
      >
        <STextInput
          label={'Имя записи'}
          name={'name'}
        />
        <STextInput
          label={'Значение'}
          name={'value'}
        />
      </SForm>
    </SDialog>
  );
}

export default RecordsDialog;