import {ArticleTypeEntityT} from "../article-types/article-types-provider";
import {CommonEntityProvider, CommonReactComponent} from "../../definitions/common.defenitions";
import {createContext, FC, useContext} from "react";
import {useGet} from "../../transport/useGet";
import {EndpointsEnum} from "../../transport/endpoints";
import {useTransport} from "../../transport/transport-provider";

export type ArticleEntityT = {
  id: number;
  title: string;
  content: string;
  published: boolean;
  type: ArticleTypeEntityT;
  typeId: number;
}

export type CreateArticleApiRequestT = ArticleEntityT;
export type UpdateArticleApiRequestT = Partial<ArticleEntityT>;
export type ArticlesContextT = CommonEntityProvider<ArticleEntityT[]> & {
  create: (entity: CreateArticleApiRequestT) => any;
  open: (id: number) => Promise<ArticleEntityT>;
  deleteArticle: (id: number) => void;
}

const context = createContext<ArticlesContextT>(null!);
const ArticlesContextProvider = context.Provider;
const useArticle = () => useContext(context);

const ArticlesProvider: FC<CommonReactComponent> = (props) => {
  const {
    children,
  } = props;

  const {
    send,
  } = useTransport();

  const {
    isLoading,
    data,
  } = useGet<ArticleEntityT[]>({
    key: EndpointsEnum.ARTICLES,
  });

  const create = (entity: CreateArticleApiRequestT) => {
    send<CreateArticleApiRequestT>({
      method: entity.id ? 'PATCH' : 'POST',
      path: entity.id ? [EndpointsEnum.ARTICLES, entity.id].join('/') : EndpointsEnum.ARTICLES,
      data: entity,
    });
  }

  const deleteArticle = (id: number) => {
    send({
      path: EndpointsEnum.ARTICLES,
      method: 'DELETE',
      data: id,
    });
  }

  const open = (id: number) => {
    return send<{ id: number }, ArticleEntityT>({
      method: 'GET',
      path: [EndpointsEnum.ARTICLES, id].join('/'),
    });
  }

  const ctx: ArticlesContextT = {
    create,
    open,
    deleteArticle,
    data,
    isLoading,
  }

  return (
    <ArticlesContextProvider
      value={ctx}
    >
      {children}
    </ArticlesContextProvider>
  )
}

export default ArticlesProvider;
export {
  ArticlesProvider,
  useArticle,
}