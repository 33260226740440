import React, {useState} from "react";
import {UndefT} from "../../definitions/common.defenitions";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import * as R from "ramda";
import SGrid from "../../components/grid/grid";
import {Button} from "primereact/button";
import {RoleEntityT, useRoles} from '../../models/roles/roles-provider';
import RolesDialog from './components/roles-dialog';
import {useUser} from "../../models/user/user-provider";
import {PermissionsEnum} from "../../constants/permissions";

const RolesPage = () => {
  const [dialogIsVisible, dialogSetIsVisible] = useState(false);
  const [entity, setEntity] = useState<UndefT<RoleEntityT>>(null!);
  const {
    isLoading,
    data,
    open,
    deleteRole,
  } = useRoles();
  const {
    rights,
  } = useUser();

  const onRemove = (entity: RoleEntityT) => {
    confirmDialog({
      message: 'Хотите удалить запись? Действие нельзя будет отменить!',
      header: 'Подтвердите удаление',
      acceptLabel: 'Да',
      rejectLabel: 'Отмена',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => deleteRole(entity.id!, onHide),
    });
  }

  const onSelect = async (selected: RoleEntityT) => {
    if (R.isNil(selected) || R.isNil(selected.id)) {
      return;
    }

    const entity = await open(selected.id);
    setEntity(entity);
    dialogSetIsVisible(true);
  }

  const onHide = () => {
    setEntity(null);
    dialogSetIsVisible(false);
  }

  const headerElement = (
    <div
      className={'flex justify-content-between'}
    >
      <span>Роли</span>
      <div
        className={'flex'}
      >
        <RolesDialog
          entity={entity}
          dialogConfig={{
            dialogConfig: {
              visible: dialogIsVisible,
              onHide,
            },
            buttonConfig: {
              onClick: () => dialogSetIsVisible(true),
            },
          }}
        />
      </div>
    </div>
  );

  return (
    <>
      <SGrid
        header={headerElement}
        selectionMode={'single'}
        value={data}
        paginator={true}
        rows={20}
        loading={isLoading}
        onRowClick={({ data }) => onSelect(data as RoleEntityT)}
        columns={[
          // {
          //   selectionMode: 'multiple',
          //   headerStyle: { width: '3rem' },
          // },
          {
            field: 'id',
            header: 'id #',
          },
          {
            field: 'name',
            header: 'Имя роли',
          },
          {
            headerStyle: { width: '100px' },
            body: (data, options) => (
              <Button
                disabled={!rights?.includes(PermissionsEnum.ROLES_DELETE)}
                icon="pi pi-times"
                onClick={() => onRemove(data)}
                severity="danger"
                outlined
              />
            ),
          },
        ]}
      />
      <ConfirmDialog/>
    </>
  );
}

export default RolesPage;