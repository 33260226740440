import React from 'react';
import 'moment/locale/ru';
import moment from 'moment';
import {RouterProvider} from 'react-router-dom';

import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import router from './navigation/router';

moment.locale('ru');

function App() {
  return <RouterProvider router={router} />;
}

export default App;
