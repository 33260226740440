import {CommonFieldPropsT} from "../../../definitions/common.defenitions";
import {FC} from "react";
import SField from "../../field.component";
import {classNames} from "primereact/utils";
import {Editor} from "primereact/editor";

export type SEditorInputPropsT = CommonFieldPropsT & {

}

const SEditorInput:FC<SEditorInputPropsT> = (props) => {
  const {
    style,
  } = props;

  return (
    <SField
      {...props}
      render={({ field, fieldState }) => (
        <Editor
          id={field.name}
          {...field}
          style={{
            height: '320px',
            ...style,
          }}
          onTextChange={(e) => field.onChange(e.htmlValue)}
          className={classNames({ 'p-invalid': fieldState.invalid })}
        />
      )}
    />
  );
}

export default SEditorInput;