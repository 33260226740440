import {CommonEntityProvider, CommonReactComponent} from '../../definitions/common.defenitions';
import {createContext, FC, useContext, useState} from 'react';
import {useTransport} from '../../transport/transport-provider';
import {useGet} from '../../transport/useGet';
import {EndpointsEnum} from '../../transport/endpoints';
import {isFunction} from '../../utils/function';

export type DistrictEntityT = {
  id: number;
  name: string;
}

export type CreateDistrictApiRequestT = DistrictEntityT;
export type UpdateDistrictApiRequestT = Partial<DistrictEntityT>;
export type DistrictsContextT = CommonEntityProvider<DistrictEntityT[]> & {
  create: (entity: CreateDistrictApiRequestT, cb?: () => void) => any;
  deleteDistrict: (id: number, cb?: () => void) => void;
  open: (id: number) => Promise<DistrictEntityT>;
}

const context = createContext<DistrictsContextT>(null!);
const DistrictsContextProvider = context.Provider;
const useDistricts = () => useContext(context);

const DistrictsProvider: FC<CommonReactComponent> = (props) => {
  const {
    children,
  } = props;

  const {
    send,
  } = useTransport();

  const [time, setTime] = useState(Date.now());

  const {
    isLoading,
    data,
  } = useGet<DistrictEntityT[]>({
    key: EndpointsEnum.DISTRICTS,
    params: {
      time,
    }
  });

  const create = async (entity: CreateDistrictApiRequestT, cb?: () => void) => {
    await send<CreateDistrictApiRequestT>({
      method: entity.id ? 'PATCH' : 'POST',
      path: entity.id ? [EndpointsEnum.DISTRICTS, entity.id].join('/') : EndpointsEnum.DISTRICTS,
      data: entity,
    });
    setTime(Date.now());
    if (isFunction(cb)) {
      cb();
    }
  }

  const deleteDistrict = async (id: number, cb?: () => void) => {
    await send({
      path: EndpointsEnum.DISTRICTS,
      method: 'DELETE',
      data: id,
    });
    setTime(Date.now());
    if (isFunction(cb)) {
      cb();
    }
  }

  const open = (id: number) => {
    return send<{ id: number }, DistrictEntityT>({
      method: 'GET',
      path: [EndpointsEnum.DISTRICTS, id].join('/'),
      showMessageRequired: false,
    });
  }

  const ctx: DistrictsContextT = {
    create,
    open,
    deleteDistrict,
    data,
    isLoading,
  }

  return (
    <DistrictsContextProvider
      value={ctx}
    >
      {children}
    </DistrictsContextProvider>
  )
}

export default DistrictsProvider;
export {
  DistrictsProvider,
  useDistricts,
}