import {createContext, FC, useContext, useEffect, useState} from 'react';
import {CommonEntityProvider, CommonReactComponent} from '../../definitions/common.defenitions';
import {useTransport} from '../../transport/transport-provider';
import {useAuth} from '../../providers/auth/auth-provider';
import {EndpointsEnum} from '../../transport/endpoints';
import {RoutesEnum} from '../../navigation/routes';
import {useNavigate} from 'react-router-dom';
import {RoleEntityT} from "../roles/roles-provider";
import * as R from "ramda";

export type UserContextT = CommonEntityProvider & {
  login: (data: LoginApiRequestT) => Promise<void>;
  logout: () => void;
  signup: any;
  getById: (data: any) => any;
  rights?: string[];
}

export type LoginApiRequestT = {
  login: string;
  password: string;
}

export type LoginApiResponseT = {
  access_token: string;
  roles: RoleEntityT[];
}

const context = createContext<UserContextT>(null!);
const UserContextProvider = context.Provider;
const useUser = () => useContext(context);

const UserProvider: FC<CommonReactComponent> = (props) => {
  const [progress, setProgress] = useState(false);
  const [rights, setRights] = useState<string[]>();

  const {
    children,
  } = props;
  const {
    send,
  } = useTransport();
  const {
    storeToken,
    clearToken,
  } = useAuth();
  const navigate = useNavigate();

  const getRightsFromStorage = () => {
    if (typeof window === 'undefined') {
      return;
    }
    const storedRights = localStorage.getItem('rights');
    if (!R.isNil(storedRights)) {
      setRights(JSON.parse(storedRights));
    }
    return storedRights;
  }

  useEffect(() => {
    getRightsFromStorage();
  }, []);

  const storeRights = (value: any) => {
    setRights(value);
    localStorage.setItem('rights', JSON.stringify(value));
  }

  const clearRights = () => {
    setRights(undefined);
    localStorage.removeItem('rights');
  }

  const login = async (data: LoginApiRequestT) => {
    setProgress(true);
    try {
      const result = await send<LoginApiRequestT, LoginApiResponseT>({
        path: EndpointsEnum.LOGIN,
        data,
        auth: false,
      });
      storeToken(result.access_token);
      storeRights(getUserRights(result.roles))
      setProgress(false);
      navigate(RoutesEnum.HOME);
    } catch (e: any) {
      console.log('login error', e);
      setProgress(false);
    }
  }

  const getUserRights = (roles: any[]) => {
    return (roles ?? []).reduce((acc, role) => {
      return [...acc, ...role.rights];
    }, []);
  }

  const getById = async (id: number) => {
    try {
      return await send({
        path: '/users',
        method: 'GET',
        data: {
          id,
        }
      });
    } catch (e) {
      console.log('error', e);
    }
  }

  const logout = () => {
    clearToken();
    clearRights();
    navigate(RoutesEnum.ROOT);
  }

  const signup = (data: any) => {
    send({ path: EndpointsEnum.USERS, data });
  }

  const ctx: UserContextT = {
    login,
    logout,
    signup,
    getById,
    rights,
    isLoading: progress,
  };

  return (
    <UserContextProvider value={ctx}>
      {children}
    </UserContextProvider>
  );
}

export default UserProvider;
export {
  UserProvider,
  useUser,
}
