import * as yup from "yup";

import {CommonReactComponent} from "../../../definitions/common.defenitions";
import {SDialog, SDialogProps} from "../../../components/dialog/SDialog";
import SForm from "../../../components/form/form.component";
import STextInput from "../../../components/inputs/text-input/text-input";
import {PageEntityT, usePages} from "../../../models/pages/pages-provider";
import SDropdown from "../../../components/inputs/dropdown-input/drop-down-input";
import SSwitcherInput from "../../../components/inputs/switcher-input/switcher-input";
import {useArticle} from "../../../models/articles/articles-provider";
import {useDocumentType} from "../../../models/document-type/document-type-provider";
import {PermissionsEnum} from "../../../constants/permissions";
import {useUser} from "../../../models/user/user-provider";

export type PageDialogPropsT = CommonReactComponent & {
  dialogConfig?: SDialogProps;
  entity?: PageEntityT | null;
}

const PageDialog = (props: PageDialogPropsT) => {
  const {
    entity,
    dialogConfig,
  } = props;

  const {
    create,
    data,
    isLoading,
  } = usePages();
  const {
    isLoading: articlesIsLoading,
    data: articlesData,
  } = useArticle();
  const {
    isLoading: docTypesIsLoading,
    data: documentTypesData,
  } = useDocumentType();
  const {
    rights,
  } = useUser();

  return (
    <SDialog
      canAdd={rights?.includes(PermissionsEnum.PAGES_SAVE)}
      buttonLabel={'Новая страница'}
      dialogTitle={'Страница сайта'}
      dialogConfig={{
        style: { width: '50vw' },
        ...dialogConfig?.dialogConfig,
      }}
      buttonConfig={{
        ...dialogConfig?.buttonConfig,
      }}
    >
      <SForm
        disabled={!rights?.includes(PermissionsEnum.PAGES_SAVE)}
        onSubmit={(entity) => create(entity, () => dialogConfig?.dialogConfig?.onHide)}
        defaultValues={entity ?? {
          parentId: null,
          order: 0,
          url: '',
          icon: '',
          title: '',
          subTitle: '',
          imageUrl: '',
          isStatic: false,
          hasDisclaimer: false,
          disclaimer: '',
          disclaimerUrl: '',
          hasDocuments: false,
          categoryId: null,
          subCategoryId: null,
          year: 2024,
          hasArticle: false,
          articleId: null,
          published: true,
          disableCategory: false,
          disableSubCategory: false,
        }}
        schema={yup.object({
          url: yup.string().required('URL обязателен!'),
          title: yup.string().required('Заголовок обязателен!'),
          order: yup.string().required('Порядок обязателен!'),
        })}
      >
        <SDropdown
          name={'parentId'}
          label={'Родительская страница'}
          options={data}
          disabled={isLoading}
          optionValue={'id'}
          optionLabel={'title'}
        />
        <STextInput
          type={'number'}
          label={'Порядок отображения'}
          name={'order'}
        />
        <STextInput
          label={'URL'}
          name={'url'}
        />
        <STextInput
          label={'Заголовок'}
          name={'title'}
        />
        <STextInput
          label={'Подзаголовок'}
          name={'subtitle'}
        />
        <SSwitcherInput
          name={'isStatic'}
          label={'Статичная'}
        />
        <STextInput
          label={'Имя иконки'}
          name={'icon'}
        />
        <STextInput
          label={'URL Картинки'}
          name={'imageUrl'}
        />
        <SSwitcherInput
          name={'hasDisclaimer'}
          label={'Установить дисклеймер'}
        />
        <STextInput
          label={'Текст дисклеймера'}
          name={'disclaimer'}
        />
        <STextInput
          label={'URL дисклеймера'}
          name={'disclaimerUrl'}
        />
        <SSwitcherInput
          name={'hasDocuments'}
          label={'Показать документы'}
        />
        <SDropdown
          name={'categoryId'}
          label={'Категория документов'}
          options={documentTypesData}
          disabled={docTypesIsLoading}
          optionValue={'id'}
          optionLabel={'name'}
        />
        <SSwitcherInput
          label={'Заблокировать смену категории'}
          name={'disableCategory'}
        />
        <SDropdown
          name={'subCategoryId'}
          label={'Подкатегория документов'}
          options={documentTypesData}
          disabled={docTypesIsLoading}
          optionValue={'id'}
          optionLabel={'name'}
        />
        <SSwitcherInput
          label={'Заблокировать смену подкатегории'}
          name={'disableSubCategory'}
        />
        <STextInput
          type={'number'}
          label={'Год'}
          name={'year'}
        />
        <SSwitcherInput
          name={'hasArticle'}
          label={'Показать Статью'}
        />
        <SDropdown
          name={'articleId'}
          label={'Статья'}
          options={articlesData}
          disabled={articlesIsLoading}
          optionValue={'id'}
          optionLabel={'title'}
        />
        <SSwitcherInput
          name={'published'}
          label={'Опубликована'}
        />
      </SForm>
    </SDialog>
  );
}

export default PageDialog;