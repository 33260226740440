import {CommonReactComponent} from "../../../definitions/common.defenitions";
import {SDialog, SDialogProps} from "../../../components/dialog/SDialog";
import SForm from "../../../components/form/form.component";
import STextInput from "../../../components/inputs/text-input/text-input";
import * as yup from "yup";
import {FacilityEntityT, useFacilities} from "../../../models/facilities/facilities-provider";
import {useDistricts} from "../../../models/districts/district-provider";
import SDropdown from "../../../components/inputs/dropdown-input/drop-down-input";
import {useUser} from "../../../models/user/user-provider";
import {PermissionsEnum} from "../../../constants/permissions";
import React from "react";

export type FacilityDialogPropsT = CommonReactComponent & {
  dialogConfig?: SDialogProps;
  entity?: FacilityEntityT | null;
}

const FacilityDialog = (props: FacilityDialogPropsT) => {
  const {
    entity,
    dialogConfig,
  } = props;

  const {
    create,
  } = useFacilities();
  const {
    data: districtsData,
  } = useDistricts();
  const {
    rights,
  } = useUser();

  return (
    <SDialog
      canAdd={rights?.includes(PermissionsEnum.FACILITIES_SAVE)}
      buttonLabel={'Новый объект'}
      dialogTitle={'Объект электросетевого хозяйства'}
      dialogConfig={{
        style: { width: '50vw' },
        ...dialogConfig?.dialogConfig,
      }}
      buttonConfig={{
        ...dialogConfig?.buttonConfig,
      }}
    >
      <SForm
        disabled={!rights?.includes(PermissionsEnum.FACILITIES_SAVE)}
        onSubmit={(entity) => create(entity, () => dialogConfig?.dialogConfig?.onHide)}
        defaultValues={entity ?? {
          name: '',
        }}
        schema={yup.object({
          name: yup.string().required('Наименование обязательно!'),
        })}
      >
        <STextInput
          label={'Наименование'}
          name={'name'}
        />
        <SDropdown
          label={'Район'}
          name={'districtId'}
          options={districtsData}
          optionValue={'id'}
          optionLabel={'name'}
        />
        <STextInput
          label={'Адрес'}
          name={'address'}
        />
        <STextInput
          label={'Подробности'}
          name={'description'}
        />
        <div
          className={'flex flex-row'}
        >
          <STextInput
            label={'Широта'}
            name={'lat'}
            type={'number'}
          />
          <div
            className={'ml-3'}
          >
            <STextInput
              label={'Долгота'}
              name={'lng'}
              type={'number'}
            />
          </div>
      </div>
    </SForm>
</SDialog>
)
  ;
}

export default FacilityDialog;