import React from 'react';
import {useUser} from '../../../models/user/user-provider';
import {SDialog} from '../../../components/dialog/SDialog';
import SForm from '../../../components/form/form.component';
import STextInput from '../../../components/inputs/text-input/text-input';

const AuthDialogComponent = () => {
  const {
    login,
  } = useUser();

  return (
    <SDialog
      canAdd={true}
    >
      <SForm
        defaultValues={{
          login: '',
          password: '',
        }}
        submitText={'Вход'}
        onSubmit={(data) => login(data)}
      >
        <STextInput
          label={'Логин'}
          name={'login'}
        />
        <STextInput
          label={'Пароль'}
          name={'password'}
          type={'password'}
        />
      </SForm>
    </SDialog>
  );
}

export default AuthDialogComponent;
