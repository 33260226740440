import moment from "moment";

export const createISOString = (date: any, hours: number, minutes: number) => {
  let momentDate = moment(date);

  momentDate.hours(hours);
  momentDate.minutes(minutes);
  momentDate.seconds(0);
  momentDate.milliseconds(0);

  return momentDate.toISOString();
}