import * as R from "ramda";

import React, {useState} from "react";
import {UndefT} from "../../definitions/common.defenitions";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import SGrid from "../../components/grid/grid";
import {Button} from "primereact/button";
import {PageEntityT, usePages} from "../../models/pages/pages-provider";
import PageDialog from "./components/page-dialog";
import clsx from "clsx";
import {InputSwitch} from "primereact/inputswitch";
import {useUser} from "../../models/user/user-provider";
import {PermissionsEnum} from "../../constants/permissions";

const PagesPage = () => {
  const [dialogIsVisible, dialogSetIsVisible] = useState(false);
  const [isTree, setIsTree] = useState(false);
  const [entity, setEntity] = useState<UndefT<PageEntityT>>(null!);
  const {
    isLoading,
    data,
    open,
    deletePage,
  } = usePages();
  const {
    rights,
  } = useUser();

  const onRemove = (entity: PageEntityT) => {
    confirmDialog({
      message: 'Хотите удалить страницу сайта? Действие нельзя будет отменить!',
      header: 'Подтвердите удаление',
      acceptLabel: 'Да',
      rejectLabel: 'Отмена',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => deletePage(entity.id!, onHide),
    });
  }

  const onSelect = async (selected: PageEntityT) => {
    if (R.isNil(selected) || R.isNil(selected.id)) {
      return;
    }

    const entity = await open(selected.id);
    setEntity(entity);
    dialogSetIsVisible(true);
  }

  const onHide = () => {
    setEntity(null);
    dialogSetIsVisible(false);
  }

  const headerElement = (
    <div
      className={'flex justify-content-between'}
    >
      <span>Записи</span>
      <div
        className={'flex'}
      >
        <div
          className={'flex align-items-center'}
        >
          <div
            className={'flex align-items-center'}
          >
            <span className={'mr-1 text-xs'}>Дерево</span>
            <InputSwitch
              name={'isTree'}
              className={'mr-3'}
              checked={isTree}
              onChange={(e) => setIsTree(e.target.value)}
            />
          </div>
          <PageDialog
            entity={entity}
            dialogConfig={{
              dialogConfig: {
                visible: dialogIsVisible,
                onHide,
              },
              buttonConfig: {
                onClick: () => dialogSetIsVisible(true),
              },
            }}
          />
        </div>
      </div>
    </div>
  );

  const verifiedBodyTemplate = (rowData: PageEntityT) => {
    return <i className={clsx('pi', { 'true-icon pi-check-circle': rowData.published, 'false-icon pi-times-circle': !rowData.published })}></i>;
  };

  // const mappedTreeData = data?.filter((itm) => R.isNil(itm.parentId)).map((itm) => entitiesListToTreeNodes<PageEntityT>(data, itm));

  return (
    <>
      <SGrid
        header={headerElement}
        selectionMode={'single'}
        value={data}
        paginator={true}
        rows={20}
        loading={isLoading}
        onRowClick={({ data }) => onSelect(data as PageEntityT)}
        columns={[
          {
            field: 'id',
            header: 'id #',
          },
          {
            field: 'title',
            header: 'Заголовок',
          },
          {
            field: 'url',
            header: 'URL',
          },
          {
            headerStyle: { width: '150px' },
            field: 'published',
            header: 'Опубликована',
            body: verifiedBodyTemplate,
            align: 'center',
          },
          {
            headerStyle: { width: '100px' },
            body: (data, options) => (
              <Button
                disabled={!rights?.includes(PermissionsEnum.PAGES_DELETE)}
                icon="pi pi-times"
                onClick={() => onRemove(data)}
                severity="danger"
                outlined
              />
            ),
          },
        ]}
      />
      <ConfirmDialog/>
    </>
  );
}

export default PagesPage;