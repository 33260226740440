import moment from 'moment';
import {createContext, Dispatch, FC, SetStateAction, useContext, useState} from 'react';
import {CommonEntityProvider, CommonReactComponent} from '../../definitions/common.defenitions';
import {EndpointsEnum} from '../../transport/endpoints';
import {useTransport} from '../../transport/transport-provider';
import {Outlet} from 'react-router-dom';
import {useGet} from '../../transport/useGet';
import {FileEntityT} from '../files/files-provider';
import {DocumentTypeEntityT} from '../document-type/document-type-provider';
import {isFunction} from "../../utils/function";

export type DocumentEntityT = {
  id?: number;
  name: string;
  description?: string;
  file?: FileEntityT;
  fileId?: number;
  fileUrl: string;
  fileName: string;
  documentType?: DocumentTypeEntityT;
  documentTypeId: number;
  publishedDate?: string;
  published?: boolean;
  year?: number;
}

export type CreateDocumentApiRequestT = DocumentEntityT;

export type UpdateDocumentApiRequestT = DocumentEntityT & {
}

export type DocumentsContextT = CommonEntityProvider<DocumentEntityT[]> & {
  create: (entity: CreateDocumentApiRequestT) => any;
  open: (id: number) => Promise<DocumentEntityT>;
  deleteDoc: (id: number, cb?: () => void) => void;
  mutate: () => void;
  documentTypeId: DocumentTypeEntityT;
  setDocumentTypeId: Dispatch<SetStateAction<DocumentTypeEntityT>>;
}

const context = createContext<DocumentsContextT>(null!);
const DocumentsContextProvider = context.Provider;
const useDocuments = () => useContext(context);

const DocumentsProvider: FC<CommonReactComponent> = (props) => {
  const [documentTypeId, setDocumentTypeId] = useState<DocumentTypeEntityT>(null!);
  const [time, setTime] = useState(Date.now());
  const {
    data = [],
    isLoading,
    mutate,
  } = useGet<DocumentEntityT[]>({
    key: EndpointsEnum.DOCUMENTS,
    params: {
      ...(documentTypeId ? { documentTypeId } : {}),
      time,
    }
  });
  const {
    send,
  } = useTransport();

  const create = (entity: CreateDocumentApiRequestT) => {
    send<CreateDocumentApiRequestT>({
      method: entity.id ? 'PATCH' : 'POST',
      path: entity.id ? [EndpointsEnum.DOCUMENTS, entity.id].join('/') : EndpointsEnum.DOCUMENTS,
      data: entity,
    });
  }

  const open = async (id: number) => {
    return send<{ id: number }, DocumentEntityT>({
      method: 'GET',
      path: [EndpointsEnum.DOCUMENTS, id].join('/'),
    });
  }

  const deleteDoc = async (id: number, cb?: () => void) => {
    await send({
      path: EndpointsEnum.DOCUMENTS,
      method: 'DELETE',
      data: id,
    });
    setTime(Date.now());
    if (isFunction(cb)) {
      cb();
    }
  }

  const ctx: DocumentsContextT = {
    create,
    open,
    deleteDoc,
    mutate,
    data: data.map((doc) => ({
      ...doc,
      publishedDate: moment.utc(doc.publishedDate).local().format('DD MMMM YYYY hh:MM'),
    })),
    isLoading,
    documentTypeId,
    setDocumentTypeId,
  }

  return (
    <DocumentsContextProvider
      value={ctx}
    >
      <Outlet/>
    </DocumentsContextProvider>
  );
}

export default DocumentsProvider;

export {
  DocumentsProvider,
  useDocuments,
}
