import {CommonEntityProvider, CommonReactComponent} from "../../definitions/common.defenitions";
import {createContext, FC, useContext, useState} from "react";
import {useTransport} from "../../transport/transport-provider";
import {useGet} from "../../transport/useGet";
import {EndpointsEnum} from "../../transport/endpoints";
import {isFunction} from "../../utils/function";
import {FileEntityT} from "../files/files-provider";
import {ArticleEntityT} from "../articles/articles-provider";

export type PageEntityT = {
  id: number;
  parentId?: number;
  order: number;
  url: string;
  title: string;
  subTitle?: string;
  isStatic: boolean;
  imageId?: number;
  imageUrl?: string;
  imageName?: string;
  image?: FileEntityT;
  icon?: string;
  hasDisclaimer: boolean;
  disclaimer?: string;
  disclaimerUrl?: string;
  disableCategory?: boolean;
  disableSubCategory?: boolean;
  hasDocuments: boolean;
  categoryId?: number;
  subCategoryId?: number;
  year?: number;
  hasArticle: boolean;
  articleId?: number;
  article?: ArticleEntityT;
  published: boolean;
}

export type CreatePageApiRequestT = PageEntityT;
export type UpdatePageApiRequestT = Partial<PageEntityT>;
export type PagesContextT = CommonEntityProvider<PageEntityT[]> & {
  create: (entity: CreatePageApiRequestT, cb?: () => void) => void;
  deletePage: (id: number, cb?: () => void) => void;
  open: (id: number) => Promise<PageEntityT>;
}

const context = createContext<PagesContextT>(null!);
const PagesContextProvider = context.Provider;
const usePages = () => useContext(context);

const PagesProvider: FC<CommonReactComponent> = (props) => {
  const {
    children,
  } = props;
  const [time, setTime] = useState(Date.now());

  const {
    send,
  } = useTransport();

  const {
    isLoading,
    data,
  } = useGet<PageEntityT[]>({
    key: EndpointsEnum.PAGES,
    params: {
      time,
    },
  });

  const create = (entity: CreatePageApiRequestT, cb?: () => void) => {
    send<CreatePageApiRequestT>({
      method: entity.id ? 'PATCH' : 'POST',
      path: entity.id ? [EndpointsEnum.PAGES, entity.id].join('/') : EndpointsEnum.PAGES,
      data: entity,
    });
    setTime(Date.now());
    if (isFunction(cb)) {
      cb();
    }
  }

  const deletePage = async (id: number, cb?: () => void) => {
    await send({
      path: EndpointsEnum.PAGES,
      method: 'DELETE',
      data: id,
    });
    setTime(Date.now());
    if (isFunction(cb)) {
      cb();
    }
  }

  const open = (id: number) => {
    return send<{ id: number }, PageEntityT>({
      method: 'GET',
      path: [EndpointsEnum.PAGES, id].join('/'),
      showMessageRequired: false,
    });
  }

  const ctx: PagesContextT = {
    create,
    data,
    isLoading,
    deletePage,
    open,
  }

  return (
    <PagesContextProvider
      value={ctx}
    >
      {children}
    </PagesContextProvider>
  )
}

export default PagesProvider;
export {
  PagesProvider,
  usePages,
}