// import {useState} from 'react';
import * as R from 'ramda';

import {DocumentTypeEntityT, useDocumentType} from '../../models/document-type/document-type-provider';
import {DocumentTypeDialog} from './components/document-type-dialog';
import STreeTable from '../../components/grid-tree/grid-tree';
import {entitiesListToTreeNodes} from "../../utils/list";
import {confirmDialog, ConfirmDialog} from "primereact/confirmdialog";
import React from "react";
import {Button} from "primereact/button";

const DocumentTypesPage = () => {
  // const [selectedTypes, setSelectedTypes] = useState();
  const {
    data,
    isLoading,
    deleteType,
  } = useDocumentType();

  const headerElement = (
    <div
      className={'flex justify-content-between'}
    >
      <span>Категории (типы) документов</span>
      <DocumentTypeDialog/>
    </div>
  );

  const onRemove = (entity: DocumentTypeEntityT) => {
    confirmDialog({
      message: 'Хотите удалить объект? Действие нельзя будет отменить!',
      header: 'Подтвердите удаление',
      acceptLabel: 'Да',
      rejectLabel: 'Отмена',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => deleteType(entity.id!),
    });
  }

  // const entitiesListToTreeNodes = (list: DocumentTypeEntityT[], doc: DocumentTypeEntityT): TreeNode => {
  //   return {
  //     data: doc,
  //     children: list.filter((itm) => itm.parentId === doc.id).map((itm) => entitiesListToTreeNodes(list, itm)),
  //   }
  // }

  const mappedCategories = data?.filter((itm) => R.isNil(itm.parentId)).map((itm) => entitiesListToTreeNodes<DocumentTypeEntityT>(data, itm));

  return (
    <>
      <STreeTable
        header={headerElement}
        columns={[
          {
            selectionMode: 'multiple',
            headerStyle: { width: '3rem' },
          },
          {
            field: 'id',
            header: 'id #',
          },
          {
            field: 'name',
            header: 'Имя',
          },
          {
            field: 'description',
            header: 'Описание',
          },
          {
            headerStyle: { width: '100px' },
            body: (data, options) => {
              return (
                <Button
                  disabled={data.children?.length > 0}
                  icon="pi pi-times"
                  onClick={() => onRemove(data)}
                  severity="danger"
                  outlined
                />
              );
            },
          },
        ]}
        value={mappedCategories}
        loading={isLoading}
        paginator={true}
        rows={20}
      />
      <ConfirmDialog/>
    </>
  );
};

export default DocumentTypesPage;
