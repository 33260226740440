import {CommonReactComponent} from "../../../definitions/common.defenitions";
import {SDialog, SDialogProps} from "../../../components/dialog/SDialog";
import SForm from "../../../components/form/form.component";
import STextInput from "../../../components/inputs/text-input/text-input";
import * as yup from "yup";
import {RoleEntityT, useRoles} from '../../../models/roles/roles-provider';
import {RightEntityT, useRights} from '../../../models/rights/rights-provider';
import SPickerListInput from '../../../components/inputs/picker-list-input/picker-list-input';
import {useUser} from "../../../models/user/user-provider";
import {PermissionsEnum} from "../../../constants/permissions";

export type RolesDialogPropsT = CommonReactComponent & {
  dialogConfig?: SDialogProps;
  entity?: RoleEntityT | null;
}

const RolesDialog = (props: RolesDialogPropsT) => {
  const {
    entity,
    dialogConfig,
  } = props;

  const {
    create,
  } = useRoles();
  const {
    data: rightsData = [],
  } = useRights();
  const {
    rights,
  } = useUser();

  return (
    <SDialog
      canAdd={rights?.includes(PermissionsEnum.ROLES_SAVE)}
      buttonLabel={'Новая роль'}
      dialogTitle={'Роль'}
      dialogConfig={{
        style: { width: '50vw' },
        ...dialogConfig?.dialogConfig,
      }}
      buttonConfig={{
        ...dialogConfig?.buttonConfig,
      }}
    >
      <SForm
        disabled={!rights?.includes(PermissionsEnum.ROLES_SAVE)}
        onSubmit={(entity) => create({
          ...entity,
          rightIds: (entity.rights ?? []).map((itm: RightEntityT) => itm.id),
        }, () => dialogConfig?.dialogConfig?.onHide)}
        defaultValues={entity ?? {
          name: '',
          rights: [],
        }}
        schema={yup.object({
          name: yup.string().required('Имя обязательно!'),
        })}
      >
        <STextInput
          label={'Имя'}
          name={'name'}
        />
        <SPickerListInput
          label={'Права'}
          name={'rights'}
          options={rightsData}
        />
      </SForm>
    </SDialog>
  );
}

export default RolesDialog;