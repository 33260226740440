import * as yup from 'yup';
import {FC, useEffect} from 'react';

import {
  useForm,
  SubmitHandler,
  FormProvider,
} from 'react-hook-form';

import {Button} from 'primereact/button';
import {CommonReactComponent} from '../../definitions/common.defenitions';
import {yupResolver} from '@hookform/resolvers/yup';

export type SFormPropsT = CommonReactComponent & {
  onSubmit: (data: any) => void;
  submitText?: string;
  defaultValues: Object;
  schema?: any; // TODO
  disabled?: boolean;
}

const SForm: FC<SFormPropsT> = (props) => {
  const {
    onSubmit,
    children,
    submitText = 'Сохранить',
    defaultValues,
    schema = yup.object({}),
    disabled,
  } = props;

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
  });

  const {
    handleSubmit,
    reset,
  } = methods;

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, reset]);

  const $onSubmit: SubmitHandler<any> = onSubmit;

  return (
    <FormProvider
      {...methods}
    >
      <div className={'card'}>
        <form
          className={'p-fluid'}
          onSubmit={handleSubmit($onSubmit)}
        >
          {children}
          <Button
            disabled={disabled}
            label={submitText}
            type={'submit'}
            className={'mt-3'}
          />
        </form>
      </div>
    </FormProvider>
  );
}

export default SForm;
