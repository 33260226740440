import * as R from 'ramda';

import styles from './page.module.css';
import {Button} from 'primereact/button';
import {Card} from 'primereact/card';
import {useNavigate} from 'react-router-dom';

const HomePage = () => {
  const navigate = useNavigate();
  const cardProps = {
    pt: {
      root: {
        className: styles['home__card'],
      },
    }
  }

  const headerElement = (title: string, buttonConfig?: { cb: () => void, icon: string; label?: string }) => {
    const {
      cb,
      icon,
      label,
    } = buttonConfig ?? {};

    return (
      <div
        className={'flex justify-content-between'}
      >
        <div>{title}</div>
        <div>
          {
            !R.isNil(buttonConfig) && (
              <Button
                label={label}
                icon={icon}
                onClick={cb}
              />
            )
          }
        </div>
      </div>
    );
  }

  return (
    <div
      className={styles['container']}
    >
      <div className={styles['col']}>
        <Card
          title={
            headerElement(
              'Отключения',
              {icon: 'pi pi-bolt', cb: () => navigate('/shutdowns')}
            )
          }
          {...cardProps}
        >
          <p className="m-0">
            Плановые и внеплановые отключения.
          </p>
        </Card>
      </div>
    </div>
  );
}

export default HomePage;
