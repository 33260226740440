import {CommonFieldPropsT} from '../../../definitions/common.defenitions';
import {FC} from 'react';
import SField from '../../field.component';
import {classNames} from 'primereact/utils';
import {Calendar} from 'primereact/calendar';

export type SCalendarInputPropsT = CommonFieldPropsT & {

}

const SCalendarInput:FC<SCalendarInputPropsT> = (props) => {
  return (
    <SField
      {...props}
      render={({ field, fieldState }) => (
        <Calendar
          id={field.name}
          {...field}
          className={classNames({ 'p-invalid': fieldState.invalid })}
        />
      )}
    />
  );
}

export default SCalendarInput;