import {CommonEntityProvider, CommonReactComponent} from "../../definitions/common.defenitions";
import {createContext, FC, useContext} from "react";
import {useTransport} from "../../transport/transport-provider";
import {useGet} from "../../transport/useGet";
import {EndpointsEnum} from "../../transport/endpoints";

export type ArticleTypeEntityT = {
  id: number;
  name: string;
  description?: string;
  parentId?: number;
  published: boolean;
  hidden: boolean;
}

export type CreateArticleTypeApiRequestT = ArticleTypeEntityT;
export type UpdateArticleTypeApiRequestT = Partial<ArticleTypeEntityT>;
export type ArticleTypesContextT = CommonEntityProvider<ArticleTypeEntityT[]> & {
  create: (entity: CreateArticleTypeApiRequestT) => any;
}

const context = createContext<ArticleTypesContextT>(null!);
const ArticleTypesContextProvider = context.Provider;
const useArticleTypes = () => useContext(context);

const ArticleTypesProvider: FC<CommonReactComponent> = (props) => {
  const {
    children,
  } = props;

  const {
    send,
  } = useTransport();

  const {
    isLoading,
    data,
  } = useGet<ArticleTypeEntityT[]>({
    key: EndpointsEnum.ARTICLE_TYPES,
  });

  const create = (entity: CreateArticleTypeApiRequestT) => {
    send<CreateArticleTypeApiRequestT>({
      method: entity.id ? 'PATCH' : 'POST',
      path: entity.id ? [EndpointsEnum.ARTICLE_TYPES, entity.id].join('/') : EndpointsEnum.ARTICLE_TYPES,
      data: entity,
    });
  }

  const ctx: ArticleTypesContextT = {
    create,
    data,
    isLoading,
  }

  return (
    <ArticleTypesContextProvider
      value={ctx}
    >
      {children}
    </ArticleTypesContextProvider>
  )
}

export default ArticleTypesProvider;
export {
  ArticleTypesProvider,
  useArticleTypes,
}