import FacilitiesProvider from "../../models/facilities/facilities-provider";
import {Outlet} from "react-router-dom";
import DistrictsProvider from "../../models/districts/district-provider";

const FacilitiesLayout = () => {
  return (
    <DistrictsProvider>
      <FacilitiesProvider>
        <Outlet/>
      </FacilitiesProvider>
    </DistrictsProvider>
  );
}

export default FacilitiesLayout;