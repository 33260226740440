import {CommonEntityProvider, CommonReactComponent} from '../../definitions/common.defenitions';
import {createContext, FC, useContext, useState} from 'react';
import {useTransport} from '../../transport/transport-provider';
import {useGet} from '../../transport/useGet';
import {EndpointsEnum} from '../../transport/endpoints';
import {isFunction} from '../../utils/function';
import {RightEntityT} from '../rights/rights-provider';

export type RoleEntityT = {
  id: number;
  name: string;
  rightIds: number[];
  rights: RightEntityT[];
}

export type CreateRoleApiRequestT = RoleEntityT;
export type UpdateRoleApiRequestT = Partial<RoleEntityT>;
export type RolesContextT = CommonEntityProvider<RoleEntityT[]> & {
  create: (entity: CreateRoleApiRequestT, cb?: () => void) => any;
  deleteRole: (id: number, cb?: () => void) => void;
  open: (id: number) => Promise<RoleEntityT>;
}

const context = createContext<RolesContextT>(null!);
const RolesContextProvider = context.Provider;
const useRoles = () => useContext(context);

const RolesProvider: FC<CommonReactComponent> = (props) => {
  const {
    children,
  } = props;

  const {
    send,
  } = useTransport();

  const [time, setTime] = useState(Date.now());

  const {
    isLoading,
    data,
  } = useGet<RoleEntityT[]>({
    key: EndpointsEnum.ROLES,
    params: {
      time,
    }
  });

  const create = async (entity: CreateRoleApiRequestT, cb?: () => void) => {
    await send<CreateRoleApiRequestT>({
      method: entity.id ? 'PATCH' : 'POST',
      path: entity.id ? [EndpointsEnum.ROLES, entity.id].join('/') : EndpointsEnum.ROLES,
      data: entity,
    });
    setTime(Date.now());
    if (isFunction(cb)) {
      cb();
    }
  }

  const deleteRole = async (id: number, cb?: () => void) => {
    await send({
      path: EndpointsEnum.ROLES,
      method: 'DELETE',
      data: id,
    });
    setTime(Date.now());
    if (isFunction(cb)) {
      cb();
    }
  }

  const open = (id: number) => {
    return send<{ id: number }, RoleEntityT>({
      method: 'GET',
      path: [EndpointsEnum.ROLES, id].join('/'),
      showMessageRequired: false,
    });
  }

  const ctx: RolesContextT = {
    create,
    open,
    deleteRole,
    data,
    isLoading,
  }

  return (
    <RolesContextProvider
      value={ctx}
    >
      {children}
    </RolesContextProvider>
  )
}

export default RolesProvider;
export {
  RolesProvider,
  useRoles,
}