import React, {useState} from 'react';
import {UndefT} from '../../definitions/common.defenitions';
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog';
import * as R from 'ramda';
import SGrid from '../../components/grid/grid';
import {Button} from 'primereact/button';
import {DistrictEntityT, useDistricts} from "../../models/districts/district-provider";
import DistrictDialog from "./components/district-dialog";
import {useUser} from "../../models/user/user-provider";
import {PermissionsEnum} from "../../constants/permissions";

const DistrictsPage = () => {
  const [dialogIsVisible, dialogSetIsVisible] = useState(false);
  const [entity, setEntity] = useState<UndefT<DistrictEntityT>>(null!);
  const {
    isLoading,
    data,
    open,
    deleteDistrict,
  } = useDistricts();
  const {
    rights,
  } = useUser();

  const onRemove = (entity: DistrictEntityT) => {
    confirmDialog({
      message: 'Хотите удалить объект? Действие нельзя будет отменить!',
      header: 'Подтвердите удаление',
      acceptLabel: 'Да',
      rejectLabel: 'Отмена',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => deleteDistrict(entity.id!, onHide),
    });
  }

  const onSelect = async (selected: DistrictEntityT) => {
    if (R.isNil(selected) || R.isNil(selected.id)) {
      return;
    }

    const entity = await open(selected.id);
    setEntity(entity);
    dialogSetIsVisible(true);
  }

  const onHide = () => {
    setEntity(null);
    dialogSetIsVisible(false);
  }

  const headerElement = (
    <div
      className={'flex justify-content-between'}
    >
      <span>Районы</span>
      <div
        className={'flex'}
      >
        <DistrictDialog
          entity={entity}
          dialogConfig={{
            dialogConfig: {
              visible: dialogIsVisible,
              onHide,
            },
            buttonConfig: {
              onClick: () => dialogSetIsVisible(true),
            },
          }}
        />
      </div>
    </div>
  );

  return (
    <>
      <SGrid
        header={headerElement}
        selectionMode={'single'}
        value={data}
        paginator={true}
        rows={20}
        loading={isLoading}
        onRowClick={({ data }) => onSelect(data as DistrictEntityT)}
        columns={[
          {
            field: 'id',
            header: 'id #',
          },
          {
            field: 'name',
            header: 'Наименование',
          },
          {
            headerStyle: { width: '100px' },
            body: (data, options) => (
              <Button
                disabled={!rights?.includes(PermissionsEnum.DISTRICTS_DELETE)}
                icon="pi pi-times"
                onClick={() => onRemove(data)}
                severity="danger"
                outlined
              />
            ),
          },
        ]}
      />
      <ConfirmDialog/>
    </>
  );
}

export default DistrictsPage;