import React, {useState} from 'react';

import {InputText} from 'primereact/inputtext';
import {classNames} from 'primereact/utils';
import SField from '../../field.component';
import {CommonFieldPropsT} from '../../../definitions/common.defenitions';
import {Button} from 'primereact/button';
import {Dialog} from 'primereact/dialog';
import {FileManager} from '../../file-manager/file-manager.component';

export type STextInputPropsT = CommonFieldPropsT & {

}

export const SFileInput = (props: STextInputPropsT) => {
  const [dialogVisible, setDialogVisible] = useState(false);

  const fileManagerDialog = (cfg: {
    onSelect: (event: any) => void;
    value: any;
  }) => {
    const {
      onSelect,
    } = cfg;
    return (
      <Dialog
        visible={dialogVisible}
        onHide={() => setDialogVisible(false)}
      >
        <FileManager
          onChange={onSelect}
        />
      </Dialog>
    )
  }

  return (
    <>
      <SField
        {...props}
        render={({ field, fieldState }) => (
          <div
            className={'p-inputgroup flex-1'}
          >
            <InputText
              id={field.name}
              readOnly={true}
              {...field}
              className={classNames({ 'p-invalid': fieldState.invalid })}
              value={field.value?.name}
            />
            <Button
              icon="pi pi-search"
              type={'button'}
              onClick={() => setDialogVisible(true)}
            />
            {fileManagerDialog({
              onSelect: (file) => {
                field.onChange(file);
                setDialogVisible(false);
              },
              value: field.value,
            })}
          </div>
        )}
      />
    </>
  );
}