import React, {useState} from 'react';
import {FileUpload} from 'primereact/fileupload';

import {SDialog} from '../../dialog/SDialog';
import {isFunction} from '../../../utils/function';
import {useUser} from "../../../models/user/user-provider";
import {PermissionsEnum} from "../../../constants/permissions";

export type UploadDialogPropsT = {
  onSubmit?: () => void;
}

const UploadDialog = (props: UploadDialogPropsT) => {
  const {
    onSubmit,
  } = props;
  const {
    rights,
  } = useUser();
  const [inProgress, setInProgress] = useState(false);

  const onUpload = () => {
    if (isFunction(onSubmit)) {
      onSubmit();
    }
    setInProgress(false);
  }

  return (
    <SDialog
      canAdd={rights?.includes(PermissionsEnum.UPLOADS_SAVE)}
      buttonLabel={'Загрузить'}
      dialogTitle={'Загрузить файл'}
      dialogConfig={{
        style: { width: '50vw' },
        closable: !inProgress,
      }}
    >
      <div className={'card'}>
        <FileUpload
          name={'file'}
          url={'http://localhost:3000/upload'}
          multiple={false}
          maxFileSize={1000000}
          emptyTemplate={<p className="m-0">Перетащите файл сюда</p>}
          onBeforeUpload={() => setInProgress(true)}
          onUpload={onUpload}
        />
      </div>
    </SDialog>
  );
}

export default UploadDialog;
