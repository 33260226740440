import {useDocumentType} from '../../../models/document-type/document-type-provider';
import {SDialog} from '../../../components/dialog/SDialog';
import SForm from '../../../components/form/form.component';
import STextInput from '../../../components/inputs/text-input/text-input';
import SDropdown from '../../../components/inputs/dropdown-input/drop-down-input';
import {PermissionsEnum} from "../../../constants/permissions";
import {useUser} from "../../../models/user/user-provider";

export const DocumentTypeDialog = () => {
  const {
    data = [],
    create,
  } = useDocumentType();
  const {
    rights,
  } = useUser();

  return (
    <SDialog
      canAdd={rights?.includes(PermissionsEnum.DOCUMENT_TYPES_SAVE)}
      dialogTitle={'Тип документа (Раздел)'}
      buttonLabel={'Новый раздел'}
    >
      <SForm
        disabled={!rights?.includes(PermissionsEnum.DOCUMENT_TYPES_SAVE)}
        onSubmit={(data) => create(data)}
        defaultValues={{
          name: '',
          description: '',
          parentId: null,
        }}
      >
        <STextInput
          label={'Имя'}
          name={'name'}
        />
        <STextInput
          label={'Описание'}
          name={'description'}
        />
        <SDropdown
          label={'Родительская категория'}
          name={'parentId'}
          showClear={true}
          options={data}
          optionLabel={'name'}
          optionValue={'id'}
        />
      </SForm>
    </SDialog>
  );
}
