import RightsProvider from '../../models/rights/rights-provider';
import RolesProvider from '../../models/roles/roles-provider';
import {Outlet} from 'react-router-dom';
import EmployeesProvider from '../../models/employees/employees-provider';

const EmployeesLayout = () => {
  return (
    <RightsProvider>
      <RolesProvider>
        <EmployeesProvider>
          <Outlet/>
        </EmployeesProvider>
      </RolesProvider>
    </RightsProvider>
  );
}

export default EmployeesLayout;