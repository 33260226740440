import RecordsProvider from "../../models/records/records-provider";
import {Outlet} from "react-router-dom";

const RecordsLayout = () => {
  return (
    <RecordsProvider>
      <Outlet/>
    </RecordsProvider>
  );
}

export default RecordsLayout;