import {SDialog, SDialogProps} from "../../../components/dialog/SDialog";
import SForm from "../../../components/form/form.component";
import SEditorInput from "../../../components/inputs/editor-input/editor-input";
import {CommonReactComponent} from "../../../definitions/common.defenitions";
import STextInput from "../../../components/inputs/text-input/text-input";
import SDropdown from "../../../components/inputs/dropdown-input/drop-down-input";
import {useArticleTypes} from "../../../models/article-types/article-types-provider";
import SSwitcherInput from "../../../components/inputs/switcher-input/switcher-input";
import {ArticleEntityT, useArticle} from "../../../models/articles/articles-provider";
import * as yup from "yup";
import {useUser} from "../../../models/user/user-provider";
import {PermissionsEnum} from "../../../constants/permissions";

export type ArticleDialogComponentPropsT = CommonReactComponent & {
  dialogConfig?: SDialogProps;
  entity?: ArticleEntityT | null;
}

const ArticleDialog = (props: ArticleDialogComponentPropsT) => {
  const {
    dialogConfig,
    entity,
  } = props;
  const {
    create,
  } = useArticle();
  const {
    data: typesData = [],
    isLoading: typesIsLoading,
  } = useArticleTypes();
  const {
    rights,
  } = useUser();

  return (
    <SDialog
      canAdd={rights?.includes(PermissionsEnum.ARTICLES_SAVE)}
      buttonLabel={'Новая статья'}
      dialogTitle={'Статья'}
      dialogConfig={{
        style: { width: '50vw' },
        ...dialogConfig?.dialogConfig,
      }}
      buttonConfig={{
        ...dialogConfig?.buttonConfig,
      }}
    >
      <SForm
        disabled={!rights?.includes(PermissionsEnum.ARTICLES_SAVE)}
        onSubmit={create}
        defaultValues={entity ?? {
          title: '',
          content: '',
          published: true,
        }}
        schema={yup.object({
          title: yup.string().required('Заголовок обязателен!'),
          content: yup.string().required('Статья не должна быть пустой!'),
          typeId: yup.number().required('Выберите категорию статьи!'),
        })}
      >
        <STextInput
          label={'Заголовок'}
          name={'title'}
        />
        <SDropdown
          label={'Категория'}
          name={'typeId'}
          options={typesData}
          disabled={typesIsLoading}
          optionValue={'id'}
          optionLabel={'name'}
        />
        <SEditorInput
          name={'content'}
        />
        <SSwitcherInput
          label={'Опубликована'}
          name={'published'}
        />
      </SForm>
    </SDialog>
  );
}

export default ArticleDialog;