import {CommonEntityProvider, CommonReactComponent} from '../../definitions/common.defenitions';
import {createContext, FC, useContext, useState} from 'react';
import {useTransport} from '../../transport/transport-provider';
import {useGet} from '../../transport/useGet';
import {EndpointsEnum} from '../../transport/endpoints';
import {isFunction} from '../../utils/function';

export type RightEntityT = {
  id: number;
  name: string;
}

export type CreateRightApiRequestT = RightEntityT;
export type UpdateRightApiRequestT = Partial<RightEntityT>;
export type RightsContextT = CommonEntityProvider<RightEntityT[]> & {
  create: (entity: CreateRightApiRequestT, cb?: () => void) => any;
  deleteRight: (id: number, cb?: () => void) => void;
  open: (id: number) => Promise<RightEntityT>;
}

const context = createContext<RightsContextT>(null!);
const RightsContextProvider = context.Provider;
const useRights = () => useContext(context);

const RightsProvider: FC<CommonReactComponent> = (props) => {
  const {
    children,
  } = props;

  const {
    send,
  } = useTransport();

  const [time, setTime] = useState(Date.now());

  const {
    isLoading,
    data,
  } = useGet<RightEntityT[]>({
    key: EndpointsEnum.RIGHTS,
    params: {
      time,
    }
  });

  const create = async (entity: CreateRightApiRequestT, cb?: () => void) => {
    await send<CreateRightApiRequestT>({
      method: entity.id ? 'PATCH' : 'POST',
      path: entity.id ? [EndpointsEnum.RIGHTS, entity.id].join('/') : EndpointsEnum.RIGHTS,
      data: entity,
    });
    setTime(Date.now());
    if (isFunction(cb)) {
      cb();
    }
  }

  const deleteRight = async (id: number, cb?: () => void) => {
    await send({
      path: EndpointsEnum.RIGHTS,
      method: 'DELETE',
      data: id,
    });
    setTime(Date.now());
    if (isFunction(cb)) {
      cb();
    }
  }

  const open = (id: number) => {
    return send<{ id: number }, RightEntityT>({
      method: 'GET',
      path: [EndpointsEnum.RIGHTS, id].join('/'),
      showMessageRequired: false,
    });
  }

  const ctx: RightsContextT = {
    create,
    open,
    deleteRight,
    data,
    isLoading,
  }

  return (
    <RightsContextProvider
      value={ctx}
    >
      {children}
    </RightsContextProvider>
  )
}

export default RightsProvider;
export {
  RightsProvider,
  useRights,
}