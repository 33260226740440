import {CommonReactComponent} from "../../definitions/common.defenitions";
import ArticleTypesProvider from "../../models/article-types/article-types-provider";
import {Outlet} from "react-router-dom";
import React from "react";

const ArticleTypesLayout = (props: CommonReactComponent) => {
  return (
    <ArticleTypesProvider>
      <Outlet/>
    </ArticleTypesProvider>
  );
}

export default ArticleTypesLayout;