import {Outlet} from "react-router-dom";
import PagesProvider from "../../models/pages/pages-provider";
import ArticlesProvider from "../../models/articles/articles-provider";
import DocumentTypeProvider from "../../models/document-type/document-type-provider";

const PagesLayout = () => {
  return (
    <DocumentTypeProvider>
      <ArticlesProvider>
        <PagesProvider>
          <Outlet/>
        </PagesProvider>
      </ArticlesProvider>
    </DocumentTypeProvider>
  );
}

export default PagesLayout;