import {CommonEntityProvider, CommonReactComponent} from '../../definitions/common.defenitions';
import {createContext, FC, useContext, useState} from 'react';
import {useTransport} from '../../transport/transport-provider';
import {useGet} from '../../transport/useGet';
import {EndpointsEnum} from '../../transport/endpoints';
import {isFunction} from '../../utils/function';
import {DistrictEntityT} from "../districts/district-provider";

export type FacilityEntityT = {
  id: number;
  name: string;
  description?: string;
  districtId?: number;
  district?: DistrictEntityT;
  address?: string;
  lat?: number;
  lng?: number;
}

export type CreateFacilityApiRequestT = FacilityEntityT;
export type UpdateFacilityApiRequestT = Partial<FacilityEntityT>;
export type FacilitiesContextT = CommonEntityProvider<FacilityEntityT[]> & {
  create: (entity: CreateFacilityApiRequestT, cb?: () => void) => any;
  deleteFacility: (id: number, cb?: () => void) => void;
  open: (id: number) => Promise<FacilityEntityT>;
}

const context = createContext<FacilitiesContextT>(null!);
const FacilitiesContextProvider = context.Provider;
const useFacilities = () => useContext(context);

const FacilitiesProvider: FC<CommonReactComponent> = (props) => {
  const {
    children,
  } = props;

  const {
    send,
  } = useTransport();

  const [time, setTime] = useState(Date.now());

  const {
    isLoading,
    data,
  } = useGet<FacilityEntityT[]>({
    key: EndpointsEnum.FACILITIES,
    params: {
      time,
    }
  });

  const create = async (entity: CreateFacilityApiRequestT, cb?: () => void) => {
    await send<CreateFacilityApiRequestT>({
      method: entity.id ? 'PATCH' : 'POST',
      path: entity.id ? [EndpointsEnum.FACILITIES, entity.id].join('/') : EndpointsEnum.FACILITIES,
      data: entity,
    });
    setTime(Date.now());
    if (isFunction(cb)) {
      cb();
    }
  }

  const deleteFacility = async (id: number, cb?: () => void) => {
    await send({
      path: EndpointsEnum.FACILITIES,
      method: 'DELETE',
      data: id,
    });
    setTime(Date.now());
    if (isFunction(cb)) {
      cb();
    }
  }

  const open = (id: number) => {
    return send<{ id: number }, FacilityEntityT>({
      method: 'GET',
      path: [EndpointsEnum.FACILITIES, id].join('/'),
      showMessageRequired: false,
    });
  }

  const ctx: FacilitiesContextT = {
    create,
    open,
    deleteFacility,
    data,
    isLoading,
  }

  return (
    <FacilitiesContextProvider
      value={ctx}
    >
      {children}
    </FacilitiesContextProvider>
  )
}

export default FacilitiesProvider;
export {
  FacilitiesProvider,
  useFacilities,
}