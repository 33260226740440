import {CommonEntityProvider, CommonReactComponent} from '../../definitions/common.defenitions';
import {createContext, FC, useContext, useState} from 'react';
import {useTransport} from '../../transport/transport-provider';
import {useGet} from '../../transport/useGet';
import {EndpointsEnum} from '../../transport/endpoints';
import {isFunction} from '../../utils/function';
import {FacilityEntityT} from "../facilities/facilities-provider";
import {DistrictEntityT} from "../districts/district-provider";

export type ShutdownEntityT = {
  id: number;
  name: string;
  facilityId?: number;
  facility: FacilityEntityT;
  district: DistrictEntityT;
  districtId?: number;
  address: string;
  description?: string;
  published: boolean;
  fromDate: string;
  $$fromHr?: number;
  $$fromMin?: number;
  toDate: string;
  $$toHr?: number;
  $$toMin?: number;
}

export type CreateShutdownApiRequestT = ShutdownEntityT;
export type UpdateShutdownApiRequestT = Partial<ShutdownEntityT>;
export type ShutdownsContextT = CommonEntityProvider<ShutdownEntityT[]> & {
  create: (entity: CreateShutdownApiRequestT, cb?: () => void) => any;
  deleteShutdown: (id: number, cb?: () => void) => void;
  open: (id: number) => Promise<ShutdownEntityT>;
}

const context = createContext<ShutdownsContextT>(null!);
const ShutdownsContextProvider = context.Provider;
const useShutdowns = () => useContext(context);

const ShutdownsProvider: FC<CommonReactComponent> = (props) => {
  const {
    children,
  } = props;

  const {
    send,
  } = useTransport();

  const [time, setTime] = useState(Date.now());

  const {
    isLoading,
    data,
  } = useGet<ShutdownEntityT[]>({
    key: EndpointsEnum.SHUTDOWNS,
    params: {
      time,
    }
  });

  const create = async (entity: CreateShutdownApiRequestT, cb?: () => void) => {
    await send<CreateShutdownApiRequestT>({
      method: entity.id ? 'PATCH' : 'POST',
      path: entity.id ? [EndpointsEnum.SHUTDOWNS, entity.id].join('/') : EndpointsEnum.SHUTDOWNS,
      data: entity,
    });
    setTime(Date.now());
    if (isFunction(cb)) {
      cb();
    }
  }

  const deleteShutdown = async (id: number, cb?: () => void) => {
    await send({
      path: EndpointsEnum.SHUTDOWNS,
      method: 'DELETE',
      data: id,
    });
    setTime(Date.now());
    if (isFunction(cb)) {
      cb();
    }
  }

  const open = (id: number) => {
    return send<{ id: number }, ShutdownEntityT>({
      method: 'GET',
      path: [EndpointsEnum.SHUTDOWNS, id].join('/'),
      showMessageRequired: false,
    });
  }

  const ctx: ShutdownsContextT = {
    create,
    open,
    deleteShutdown,
    data,
    isLoading,
  }

  return (
    <ShutdownsContextProvider
      value={ctx}
    >
      {children}
    </ShutdownsContextProvider>
  )
}

export default ShutdownsProvider;
export {
  ShutdownsProvider,
  useShutdowns,
}