import {CommonFieldPropsT, NullableT} from '../../../definitions/common.defenitions';
import {FC, useEffect, useState} from 'react';
import SField from '../../field.component';
import {classNames} from 'primereact/utils';
import {PickList} from 'primereact/picklist';

export type SPickerListInputPropsT = CommonFieldPropsT & {
  value?: any[];
  options: NullableT<any[]>;
};

const SPickerListInput:FC<SPickerListInputPropsT> = (props) => {
  const {
    options = [],
  } = props;
  const [source, setSource] = useState([]);

  useEffect(() => {
    // @ts-expect-error
    setSource([...options]);
  }, [options]);

  const itemTemplate = (item: any) => {
    return (
      <span>{item.name}</span>
    );
  }

  return (
    <SField
      {...props}
      render={({ field, fieldState }) => (
        <PickList
          dataKey="id"
          source={source}
          target={field.value}
          onChange={(event) => {
            field.onChange(event.target);
            setSource(event.source);
          }}
          className={classNames({ 'p-invalid': fieldState.invalid })}
          itemTemplate={itemTemplate}
          breakpoint="1280px"
          sourceHeader="Доступно"
          targetHeader="Выбрано"
          sourceStyle={{ height: '24rem' }}
          targetStyle={{ height: '24rem' }}
        />
      )}
    />
  );
}

export default SPickerListInput;