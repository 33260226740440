export enum RoutesEnum {
  ARTICLES = '/articles',
  ARTICLE_TYPES = '/article-types',
  DISTRICTS = '/districts',
  DOCUMENTS = '/documents',
  DOCUMENT_TYPES = '/document-types',
  FACILITIES = '/facilities',
  FILES = '/files',
  HOME = '/home',
  PAGES = '/pages',
  RECORDS = '/records',
  RIGHTS = '/rights',
  ROLES = '/roles',
  ROOT = '/',
  SHUTDOWNS = '/shutdowns',
  USERS = '/users',
}
