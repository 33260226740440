import {CommonFieldPropsT} from '../../../definitions/common.defenitions';
import {FC} from 'react';
import SField from '../../field.component';
import {classNames} from 'primereact/utils';
import {InputSwitch} from 'primereact/inputswitch';

export type SSwitcherInputPropsT = CommonFieldPropsT & {

}

const SSwitcherInput:FC<SSwitcherInputPropsT> = (props) => {
  return (
    <SField
      {...props}
      render={({ field, fieldState }) => (
        <InputSwitch
          id={field.name}
          {...field}
          checked={field.value}
          className={classNames({ 'p-invalid': fieldState.invalid })}
        />
      )}
    />
  );
}

export default SSwitcherInput;