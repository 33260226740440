import {FC} from 'react';
import {Dropdown, DropdownProps} from 'primereact/dropdown';
import {classNames} from 'primereact/utils';
import {CommonFieldPropsT} from '../../../definitions/common.defenitions';
import SField from '../../field.component';

export type SDropdownProps = CommonFieldPropsT & DropdownProps & {
}

const SDropdown:FC<SDropdownProps> = (props) => {
  return (
    <SField
      {...props}
      render={({ field, fieldState }) => (
        <Dropdown
          id={field.name}
          {...props}
          {...field}
          className={classNames({ 'p-invalid': fieldState.invalid })}
        />
      )}
    />
  );
}

export default SDropdown;
