import * as R from 'ramda';
import React, {useState} from 'react';

import {DocumentEntityT, useDocuments} from '../../models/documents/documents-provider';
import DocumentDialog from './components/document-dialog';
import {getUrlExtension} from '../../utils/file';
import {IconsEnum} from '../../constants/icons';
import {FileExtensionEnum, ICONS_MAP} from '../../constants/documents';
import SGrid from '../../components/grid/grid';
import {useDocumentType} from '../../models/document-type/document-type-provider';
import {Dropdown} from 'primereact/dropdown';
import {confirmDialog, ConfirmDialog} from "primereact/confirmdialog";
import {Button} from "primereact/button";
import {FilterMatchMode} from "primereact/api";
import {useUser} from "../../models/user/user-provider";
import {PermissionsEnum} from "../../constants/permissions";


const DocumentsPage = () => {
  const [selectedProducts, setSelectedProducts] = useState<DocumentEntityT[]>(null!);
  const [dialogIsVisible, dialogSetIsVisible] = useState(false);
  const [loadedDocument, setLoadedDocument] = useState<DocumentEntityT | null>();
  const [filters, setFilters] = useState({
    year: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });

  const {
    open,
    data,
    isLoading,
    documentTypeId,
    setDocumentTypeId,
    deleteDoc,
  } = useDocuments();
  const {
    data: documentTypes,
  } = useDocumentType();
  const {
    rights,
  } = useUser();

  const onHide = () => {
    setLoadedDocument(null);
    dialogSetIsVisible(false);
  }

  const onSelectDocument = async (document: DocumentEntityT) => {
    if (R.isNil(document) || R.isNil(document.id)) {
      return;
    }

    const entity = await open(document.id);
    setLoadedDocument({
      ...entity,
      fileUrl: entity.file?.url!,
    });
    dialogSetIsVisible(true);
  }

  const onRemove = (entity: DocumentEntityT) => {
    confirmDialog({
      message: 'Хотите удалить документ? Действие нельзя будет отменить!',
      header: 'Подтвердите удаление',
      acceptLabel: 'Да',
      rejectLabel: 'Отмена',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => deleteDoc(entity.id!, onHide),
    });
  }

  const headerElement = (
    <div
      className={'flex justify-content-between'}
    >
      <span>Документы</span>
      <div
        className={'flex'}
      >
        <Dropdown
          name={'documentType'}
          className={'mr-3'}
          options={documentTypes}
          optionValue={'id'}
          optionLabel={'name'}
          placeholder={'Выберите раздел'}
          value={documentTypeId}
          showClear={true}
          onChange={(e) => setDocumentTypeId(e.value)}
        />
        <DocumentDialog
          loadedDocument={loadedDocument}
          dialogConfig={{
            dialogConfig: {
              visible: dialogIsVisible,
              // @ts-ignore
              onHide: () => {
                setLoadedDocument(null);
                dialogSetIsVisible(false);
              },
            },
            buttonConfig: {
              onClick: () => dialogSetIsVisible(true),
            },
          }}
        />
      </div>
    </div>
  );

  const asIconElement = (doc: DocumentEntityT) => {
    const ext = getUrlExtension(doc.fileUrl);
    let iconName = IconsEnum.FILE;
    if (!R.isNil(ext)) {
      iconName = ICONS_MAP[ext as FileExtensionEnum] ?? IconsEnum.FILE;
    }

    return <i className={`pi ${iconName}`}></i>;
  }

  return (
    <>
      <SGrid
        header={headerElement}
        selectionMode={'single'}
        selection={selectedProducts}
        onSelectionChange={(e: any) => setSelectedProducts(e.value)}
        onRowClick={({ data }) => onSelectDocument(data as DocumentEntityT)}
        // @ts-expect-error
        onFilter={(e) => setFilters(e.filters)}
        dataKey={'id'}
        filters={filters}
        filterDisplay="row"
        columns={[
          {
            // selectionMode: 'multiple',
            headerStyle: { width: '3rem' },
          },
          {
            field: 'id',
            header: 'id #',
          },
          {
            field: 'name',
            header: 'Имя файла',
          },
          {
            headerStyle: { width: 150 },
            field: 'year',
            header: 'Год',
            filterField: 'year',
            showFilterMenu: false,
            filterMenuStyle: { width: '2rem' },
            style: { minWidth: '2rem' },
            filter: true,
          },
          {
            body: (itm: DocumentEntityT) => (<span>{itm.publishedDate}</span>),
            header: 'Дата публикации',
          },
          {
            field: 'icon',
            header: 'Тип документа',
            body: asIconElement,
            style: { width: 150 },
          },
          {
            headerStyle: { width: '100px' },
            body: (data, options) => (
              <Button
                disabled={!rights?.includes(PermissionsEnum.DOCUMENTS_DELETE)}
                icon="pi pi-times"
                onClick={() => onRemove(data)}
                severity="danger"
                outlined
              />
            ),
          },
        ]}
        value={data}
        loading={isLoading}
        paginator={true}
        rows={20}
      />
      <ConfirmDialog/>
    </>
  );
}

export default DocumentsPage;
