import {ArticleTypeEntityT, useArticleTypes} from "../../models/article-types/article-types-provider";
import ArticleTypesDialog from "./components/article-types-dialog";
import STreeTable from "../../components/grid-tree/grid-tree";
import React, {useMemo} from "react";
import {entitiesListToTreeNodes} from "../../utils/list";
import clsx from "clsx";
import {Button} from "primereact/button";
import {TreeNode} from "primereact/treenode";
import {useUser} from "../../models/user/user-provider";
import {PermissionsEnum} from "../../constants/permissions";

const ArticleTypesPage = () => {
  const {
    data,
    isLoading,
  } = useArticleTypes();
  const {
    rights,
  } = useUser();

  const headerElement = (
    <div
      className={'flex justify-content-between'}
    >
      <span>Категории (типы) статей</span>
      <ArticleTypesDialog/>
    </div>
  );

  const mappedData = useMemo(() => (data ?? []).map((itm) => entitiesListToTreeNodes<ArticleTypeEntityT>(data, itm)), [data])

  const verifiedBodyTemplate = (rowData: TreeNode) => {
    return <i className={clsx('pi', { 'true-icon pi-check-circle': rowData?.data.published, 'false-icon pi-times-circle': !rowData?.data.published })}></i>;
  };

  const onRemove = (entity: ArticleTypeEntityT) => {}

  return (
    <STreeTable
      header={headerElement}
      // selectionMode={'checkbox'}
      // selection={selectedTypes}
      // onSelectionChange={(e) => setSelectedTypes(e.value)}
      // dataKey={'id'}
      columns={[
        {
          selectionMode: 'multiple',
          headerStyle: { width: '3rem' },
        },
        {
          field: 'id',
          header: 'id #',
        },
        {
          field: 'name',
          header: 'Имя',
        },
        {
          field: 'description',
          header: 'Описание',
        },
        {
          headerStyle: { width: '150px' },
          field: 'published',
          header: 'Опубликована',
          body: verifiedBodyTemplate,
          align: 'center',
        },
        {
          headerStyle: { width: '100px' },
          body: (data, options) => (
            <Button
              disabled={!rights?.includes(PermissionsEnum.PAGES_DELETE)}
              icon="pi pi-times"
              onClick={() => onRemove(data)}
              severity="danger"
              outlined
            />
          ),
        },
      ]}
      value={mappedData}
      loading={isLoading}
      paginator={true}
      rows={20}
    />
  );
}

export default ArticleTypesPage;