import {useEffect} from 'react';
import {useAuth} from '../providers/auth/auth-provider';
import {useMessage} from '../providers/message/message-provider';
import {useNavigate} from 'react-router-dom';

const guardedPage = (Component: any) => {
  return function GuardedPage(props: any) {
    const {
      isAuthenticated,
    } = useAuth();
    const {
      showError,
    } = useMessage();

    const navigate = useNavigate();

    useEffect(() => {
      if (!isAuthenticated) {
        showError({ message: 'Доступ только для авторизованных пользователей' });
        navigate('/');
      }
    }, [showError, isAuthenticated, navigate]);

    if (!isAuthenticated) {
      return null;
    }

    return <Component {...props} />;
  }
}

export default guardedPage;
