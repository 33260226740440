import {CommonReactComponent} from "../../../definitions/common.defenitions";
import {SDialog, SDialogProps} from "../../../components/dialog/SDialog";
import SForm from "../../../components/form/form.component";
import STextInput from "../../../components/inputs/text-input/text-input";
import * as yup from "yup";
import * as R from 'ramda';
import {EmployeeEntityT, useEmployees} from '../../../models/employees/employees-provider';
import {useRoles} from '../../../models/roles/roles-provider';
import SPickerListInput from '../../../components/inputs/picker-list-input/picker-list-input';
import React, {useEffect, useState} from 'react';
import {InputSwitch} from 'primereact/inputswitch';
import SSwitcherInput from '../../../components/inputs/switcher-input/switcher-input';
import {PermissionsEnum} from "../../../constants/permissions";
import {useUser} from "../../../models/user/user-provider";

export type EmployeeDialogPropsT = CommonReactComponent & {
  dialogConfig?: SDialogProps;
  entity?: EmployeeEntityT | null;
}

const EmployeeDialog = (props: EmployeeDialogPropsT) => {
  const {
    entity,
    dialogConfig,
  } = props;
  const [changePassword, setChangePassword] = useState(true);

  const isNewEntity = R.isNil(entity);

  useEffect(() => {
    if (!R.isNil(entity) && !R.isNil(entity.id)) {
      setChangePassword(false);
    } else {
      setChangePassword(true);
    }
  }, [entity]);

  const {
    create,
  } = useEmployees();

  const {
    data: rolesData,
  } = useRoles();
  const {
    rights,
  } = useUser();

  return (
    <SDialog
      canAdd={rights?.includes(PermissionsEnum.USERS_SAVE)}
      buttonLabel={'Новый сотрудник'}
      dialogTitle={'Сотрудник'}
      dialogConfig={{
        style: { width: '50vw' },
        ...dialogConfig?.dialogConfig,
      }}
      buttonConfig={{
        ...dialogConfig?.buttonConfig,
      }}
    >
      <SForm
        disabled={!rights?.includes(PermissionsEnum.USERS_SAVE)}
        onSubmit={(entity: EmployeeEntityT) => create({
          id: entity.id,
          name: entity.name,
          login: entity.login,
          roleIds: (entity.roles || []).map((itm) => itm.id),
          active: entity.active,
          ...(changePassword ? {
            password: entity.password,
          } : {}),
        }, () => dialogConfig?.dialogConfig?.onHide)}
        defaultValues={
        {
          ...entity,
          roles: entity?.roles ?? [],
        } ?? {
          name: '',
          login: '',
          value: '',
          active: true,
          roles: [],
        }}
        schema={yup.object({
          name: yup.string().required('Имя обязательно!'),
          login: yup.string().required('Логин обязателен!'),
          ...(
            changePassword ? {
              password: yup.string().required('Пароль обязателен'),
              passwordConfirmation: yup.string()
                .test('passwords-match', 'Пароли должны совпадать', function(value){
                  return this.parent.password === value
                })
            } : {}
          ),
        })}
      >
        <SSwitcherInput
          name={'active'}
          label={'Активинован'}
        />
        <STextInput
          label={'Имя записи'}
          name={'name'}
        />
        <STextInput
          label={'Логин'}
          name={'login'}
        />
          {
            !isNewEntity && (
              <div
                className="flex flex-column gap-2 mt-3"
              >
                <label
                  htmlFor="name"
                  className={'text-sm'}
                >
                  Изменить пароль?
                </label>
                <InputSwitch
                  checked={changePassword}
                  onChange={(e) => setChangePassword(e.value)}
                />
              </div>
            )
          }
        {
          changePassword && (
            <>
              <STextInput
                label={'Пароль'}
                name={'password'}
                type={'password'}
              />
              <STextInput
                label={'Пароль еще раз'}
                name={'passwordConfirmation'}
                type={'password'}
                />
              </>
            )
          }
          <SPickerListInput
            label={'Права'}
            name={'roles'}
            options={rolesData}
          />
      </SForm>
    </SDialog>
);
}

export default EmployeeDialog;