import {Card} from 'primereact/card';
import AuthDialogComponent from './components/auth-dialog.component';

export const AuthPage = () => {
  return (
    <div
      className={'flex justify-content-center align-items-center min-h-screen'}
    >
      <div>
        <Card
          title={'Электрические сети Сибири'}
          subTitle={'Выполните вход'}
          footer={<AuthDialogComponent/>}
          className={'md:w-25rem'}
        >
        </Card>
      </div>
    </div>
  );
}
