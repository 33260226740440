import React, {FC, useState} from 'react';

import {Button, ButtonProps} from 'primereact/button';
import {Dialog, DialogProps} from 'primereact/dialog';
import {CommonReactComponent} from '../../definitions/common.defenitions';


export type SDialogProps = CommonReactComponent & {
  buttonConfig?: ButtonProps;
  dialogConfig?: Partial<DialogProps>;
  dialogTitle?: string;
  buttonLabel?: string;
  canAdd?: boolean;
}

export const SDialog: FC<SDialogProps> = (props) => {
  const {
    buttonLabel,
    dialogTitle,
  } = props;
  const [isVisible, setIsVisible] = useState(false);

  const defaultButtonProps: ButtonProps = {
    label: buttonLabel ?? 'Войти',
    onClick: () => setIsVisible(true),
  }
  const defaultDialogProps: DialogProps = {
    header: dialogTitle ?? 'Вход',
    visible: isVisible,
    onHide: () => setIsVisible(false),
  }
  const {
    canAdd,
    children,
    buttonConfig = defaultButtonProps,
    dialogConfig = defaultDialogProps,
  } = props;

  return (
    <>
      <Button
        {...defaultButtonProps}
        {...buttonConfig}
        disabled={!canAdd}
      />
      <Dialog
        {...defaultDialogProps}
        {...dialogConfig}
      >
        {children}
      </Dialog>
    </>
  );
}
