import {CommonReactComponent} from "../../../definitions/common.defenitions";
import {SDialog, SDialogProps} from "../../../components/dialog/SDialog";
import SForm from "../../../components/form/form.component";
import STextInput from "../../../components/inputs/text-input/text-input";
import * as yup from "yup";
import {RightEntityT, useRights} from '../../../models/rights/rights-provider';
import {PermissionsEnum} from "../../../constants/permissions";
import {useUser} from "../../../models/user/user-provider";

export type RightsDialogPropsT = CommonReactComponent & {
  dialogConfig?: SDialogProps;
  entity?: RightEntityT | null;
}

const RightsDialog = (props: RightsDialogPropsT) => {
  const {
    entity,
    dialogConfig,
  } = props;

  const {
    create,
  } = useRights();
  const {
    rights,
  } = useUser();

  return (
    <SDialog
      canAdd={rights?.includes(PermissionsEnum.RIGHTS_SAVE)}
      buttonLabel={'Новое право'}
      dialogTitle={'Право'}
      dialogConfig={{
        style: { width: '50vw' },
        ...dialogConfig?.dialogConfig,
      }}
      buttonConfig={{
        ...dialogConfig?.buttonConfig,
      }}
    >
      <SForm
        disabled={!rights?.includes(PermissionsEnum.RIGHTS_SAVE)}
        onSubmit={(entity) => create(entity, () => dialogConfig?.dialogConfig?.onHide)}
        defaultValues={entity ?? {
          name: '',
        }}
        schema={yup.object({
          name: yup.string().required('Наименование обязательно!'),
        })}
      >
        <STextInput
          label={'Наименование'}
          name={'name'}
        />
      </SForm>
    </SDialog>
  );
}

export default RightsDialog;