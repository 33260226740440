import RolesProvider from '../../models/roles/roles-provider';
import {Outlet} from 'react-router-dom';
import RightsProvider from '../../models/rights/rights-provider';

const RolesLayout = () => {
  return (
    <RightsProvider>
      <RolesProvider>
        <Outlet/>
      </RolesProvider>
    </RightsProvider>
  );
}

export default RolesLayout;