import React, {FC, useEffect} from 'react';
import {CommonReactComponent} from '../definitions/common.defenitions';
import {useMessage} from '../providers/message/message-provider';
import {useUser} from '../models/user/user-provider';
import Interceptors from '../transport/interceptors';

const InterceptorsActivator: FC<CommonReactComponent> = (props) => {
  const useMessageCtx = useMessage();
  const useUserCtx = useUser();

  useEffect(() => {
    Interceptors.setupInterceptors({
      useMessage: useMessageCtx,
      useUser: useUserCtx,
    });
  }, [useMessageCtx, useUserCtx]);

  return (
    <>
      {props.children}
    </>
  );
}

export default InterceptorsActivator;
