import RightsProvider from '../../models/rights/rights-provider';
import {Outlet} from 'react-router-dom';

const RightsLayout = () => {
  return (
    <RightsProvider>
      <Outlet/>
    </RightsProvider>
  )
}

export default RightsLayout;