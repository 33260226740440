import {CommonEntityProvider, CommonReactComponent} from '../../definitions/common.defenitions';
import {createContext, FC, useContext, useState} from 'react';
import {EndpointsEnum} from '../../transport/endpoints';
import {useTransport} from '../../transport/transport-provider';
import {useGet} from '../../transport/useGet';
import {isFunction} from "../../utils/function";

export type DocumentTypeEntityT = {
  id: number;
  name: string;
  description: string;
  parentId?: number;
}

export type CreateDocumentTypeApiRequestT = Partial<DocumentTypeEntityT>;

export type DocumentTypeContextT = CommonEntityProvider<DocumentTypeEntityT[]> & {
  create: (entity: CreateDocumentTypeApiRequestT) => any;
  deleteType: (id: number, cb?: () => void) => void;
}

const context = createContext<DocumentTypeContextT>(null!);
const DocumentTypeContextProvider = context.Provider;
const useDocumentType = () => useContext(context);

const DocumentTypeProvider: FC<CommonReactComponent> = (props) => {
  const [time, setTime] = useState(Date.now());
  const {
    children,
  } = props;
  const {
    data,
    isLoading,
  } = useGet<DocumentTypeEntityT[]>({
    key: EndpointsEnum.DOCUMENT_TYPE,
    params: {
      time,
    }
  });

  const {
    send,
  } = useTransport();

  const create = (entity: CreateDocumentTypeApiRequestT) => {
    send<CreateDocumentTypeApiRequestT>({
      method: entity.id ? 'PATCH' : 'POST',
      path: entity.id ? [EndpointsEnum.DOCUMENT_TYPE, entity.id].join('/') : EndpointsEnum.DOCUMENT_TYPE,
      data: entity,
    });
  }

  const deleteType = async (id: number, cb?: () => void) => {
    await send({
      path: EndpointsEnum.DOCUMENT_TYPE,
      method: 'DELETE',
      data: id,
    });
    setTime(Date.now());
    if (isFunction(cb)) {
      cb();
    }
  }

  const ctx: DocumentTypeContextT = {
    create,
    data,
    isLoading,
    deleteType,
  }

  return (
    <DocumentTypeContextProvider
      value={ctx}
    >
      {children}
    </DocumentTypeContextProvider>
  );
}

export default DocumentTypeProvider;

export {
  DocumentTypeProvider,
  useDocumentType,
}
