import {CommonEntityProvider, CommonReactComponent} from "../../definitions/common.defenitions";
import {createContext, FC, useContext, useState} from "react";
import {useTransport} from "../../transport/transport-provider";
import {useGet} from "../../transport/useGet";
import {EndpointsEnum} from "../../transport/endpoints";
import {isFunction} from "../../utils/function";

export type RecordEntityT = {
  id: number;
  name: string;
  value: string;
}

export type CreateRecordApiRequestT = RecordEntityT;
export type UpdateRecordApiRequestT = Partial<RecordEntityT>;
export type RecordsContextT = CommonEntityProvider<RecordEntityT[]> & {
  create: (entity: CreateRecordApiRequestT, cb?: () => void) => any;
  deleteRecord: (id: number, cb?: () => void) => void;
  open: (id: number) => Promise<RecordEntityT>;
}

const context = createContext<RecordsContextT>(null!);
const RecordsContextProvider = context.Provider;
const useRecords = () => useContext(context);

const RecordsProvider: FC<CommonReactComponent> = (props) => {
  const {
    children,
  } = props;

  const {
    send,
  } = useTransport();

  const [time, setTime] = useState(Date.now());

  const {
    isLoading,
    data,
  } = useGet<RecordEntityT[]>({
    key: EndpointsEnum.RECORDS,
    params: {
      time,
    }
  });

  const create = async (entity: CreateRecordApiRequestT, cb?: () => void) => {
    await send<CreateRecordApiRequestT>({
      method: entity.id ? 'PATCH' : 'POST',
      path: entity.id ? [EndpointsEnum.RECORDS, entity.id].join('/') : EndpointsEnum.RECORDS,
      data: entity,
    });
    setTime(Date.now());
    if (isFunction(cb)) {
      cb();
    }
  }

  const deleteRecord = async (id: number, cb?: () => void) => {
    await send({
      path: EndpointsEnum.RECORDS,
      method: 'DELETE',
      data: id,
    });
    setTime(Date.now());
    if (isFunction(cb)) {
      cb();
    }
  }

  const open = (id: number) => {
    return send<{ id: number }, RecordEntityT>({
      method: 'GET',
      path: [EndpointsEnum.RECORDS, id].join('/'),
      showMessageRequired: false,
    });
  }

  const ctx: RecordsContextT = {
    create,
    open,
    deleteRecord,
    data,
    isLoading,
  }

  return (
    <RecordsContextProvider
      value={ctx}
    >
      {children}
    </RecordsContextProvider>
  )
}

export default RecordsProvider;
export {
  RecordsProvider,
  useRecords,
}