import React, {FC} from 'react';
import * as yup from 'yup';

import {CommonReactComponent} from '../../../definitions/common.defenitions';
import {DocumentEntityT, useDocuments} from '../../../models/documents/documents-provider';
import {SDialog, SDialogProps} from '../../../components/dialog/SDialog';
import SForm from '../../../components/form/form.component';
import STextInput from '../../../components/inputs/text-input/text-input';
import {SFileInput} from '../../../components/inputs/file-input/file-input';
import {useDocumentType} from '../../../models/document-type/document-type-provider';
import SDropdown from '../../../components/inputs/dropdown-input/drop-down-input';
import {isFunction} from '../../../utils/function';
import SCalendarInput from '../../../components/inputs/calendar-input/calendar-input';
import SSwitcherInput from '../../../components/inputs/switcher-input/switcher-input';
import {useUser} from "../../../models/user/user-provider";
import {PermissionsEnum} from "../../../constants/permissions";

export type DocumentDialogComponentPropsT = CommonReactComponent & {
  dialogConfig?: SDialogProps;
  loadedDocument?: DocumentEntityT | null;
}

const DocumentDialog: FC<DocumentDialogComponentPropsT> = ({
                                                             dialogConfig,
                                                             loadedDocument,
                                                           }) => {
  const {
    create,
    mutate,
  } = useDocuments();
  const {
    rights,
  } = useUser();
  const {
    data: documentTypeData = [],
  } = useDocumentType();
  const {
    // @ts-ignore
    onHide,
  } = dialogConfig;

  const defaultDocument = {
    name: '',
    url: '',
    fileUrl: null,
    typeId: null,
    published: false,
  };

  const onSubmit = (data: DocumentEntityT) => {
    create({
      id: data.id,
      fileUrl: data.file?.url!,
      name: data.name,
      documentTypeId: data.documentTypeId,
      fileId: data.file?.id!,
      fileName: data.file?.name!,
      published: data.published,
      publishedDate: data.publishedDate,
      year: data.year,
    });

    if (isFunction(onHide)) {
      onHide();
    }
    mutate();
  }

  return (
    <SDialog
      canAdd={rights?.includes(PermissionsEnum.DOCUMENTS_SAVE)}
      buttonLabel={'Новый'}
      dialogTitle={'Документ'}
      dialogConfig={{
        style: { width: '50vw' },
        ...dialogConfig?.dialogConfig,
      }}
      buttonConfig={{
        ...dialogConfig?.buttonConfig,
      }}
    >
      <SForm
        disabled={!rights?.includes(PermissionsEnum.DOCUMENTS_SAVE)}
        defaultValues={loadedDocument ?? defaultDocument}
        onSubmit={onSubmit}
        schema={yup.object({
          name: yup.string().required('Имя обязательно!'),
          file: yup.object().required('Выберите файл!'),
          documentTypeId: yup.number().required('Раздел обязателен!'),
        })}
      >
        <STextInput
          label={'Имя'}
          name={'name'}
        />
        <STextInput
          label={'Полный путь к файлу'}
          name={'fileUrl'}
        />
        <SFileInput
          name={'file'}
          label={'Выбрать файл'}
        />
        <SDropdown
          label={'Категория'}
          name={'documentTypeId'}
          options={documentTypeData}
          optionLabel={'name'}
          optionValue={'id'}
        />
        <SCalendarInput
          label={'Дата публикации'}
          name={'publishedDate'}
        />
        <STextInput
          type={'number'}
          label={'Год'}
          name={'year'}
        />
        <SSwitcherInput
          label={'Опубликован'}
          name={'published'}
        />
      </SForm>
    </SDialog>
  );
}

export default DocumentDialog;
