import React from 'react';
import {createBrowserRouter, Outlet} from 'react-router-dom';

import {PrimeReactProvider} from 'primereact/api';

import AuthProvider from '../providers/auth/auth-provider';
import MessageProvider from '../providers/message/message-provider';
import TransportProvider from '../transport/transport-provider';
import UserProvider from '../models/user/user-provider';
import InterceptorsActivator from '../hoc/interceptors-activator';
import DocumentsProvider from '../models/documents/documents-provider';

import PrivateLayout from '../pages/private-layout';
import {AuthPage} from '../pages/auth/auth-page';
import DocumentTypesPage from '../pages/document-types/page';
import DocumentsPage from '../pages/documents/page';
import FilesPage from '../pages/files/page';
import HomePage from '../pages/home/page';
import ArticlesPage from "../pages/articles/page";
import ArticleTypesPage from "../pages/article-types/page";
import ArticlesLayout from "../pages/articles/layout";
import ArticleTypesLayout from "../pages/article-types/layout";
import RecordsLayout from "../pages/records/layout";
import RecordsPage from "../pages/records/page";
import PagesLayout from "../pages/pages/layout";
import PagesPage from "../pages/pages/page";
import RightsLayout from '../pages/rights/layout';
import RightsPage from '../pages/rights/page';
import RolesLayout from '../pages/roles/layout';
import RolesPage from '../pages/roles/page';
import DistrictLayout from "../pages/district/layout";
import DistrictsPage from "../pages/district/page";
import ShutdownsLayout from "../pages/shutdowns/layout";
import ShutdownsPage from "../pages/shutdowns/page";
import FacilitiesLayout from "../pages/facilities/layout";
import FacilitiesPage from "../pages/facilities/page";
import EmployeesLayout from '../pages/employees/layout';
import EmployeesPage from '../pages/employees/page';

const ProvidersLayout = () => (
  <PrimeReactProvider>
    <AuthProvider>
      <MessageProvider>
        <TransportProvider>
          <UserProvider>
            <InterceptorsActivator>
              <Outlet/>
            </InterceptorsActivator>
          </UserProvider>
        </TransportProvider>
      </MessageProvider>
    </AuthProvider>
  </PrimeReactProvider>
);

const router = createBrowserRouter([
  {
    element: <ProvidersLayout/>,
    children: [
      {
        path: '/',
        element: <AuthPage/>,
      },
      {
        element: <PrivateLayout/>,
        children: [
          {
            path: 'document-types',
            element: <DocumentTypesPage/>,
          },
          {
            element: <DocumentsProvider/>,
            children: [
              {
                path: 'documents',
                element: <DocumentsPage/>,
              },
            ],
          },
          {
            path: 'files',
            element: <FilesPage/>,
          },
          {
            element: <ArticleTypesLayout/>,
            children: [
              {
                path: 'article-types',
                element: <ArticleTypesPage/>,
              }
            ],
          },
          {
            element: <RightsLayout/>,
            children: [
              {
                path: 'rights',
                element: <RightsPage/>,
              }
            ],
          },
          {
            element: <RolesLayout/>,
            children: [
              {
                path: 'roles',
                element: <RolesPage/>,
              }
            ],
          },
          {
            element: <ArticlesLayout/>,
            children: [
              {
                path: 'articles',
                element: <ArticlesPage/>,
              },
            ],
          },
          {
            element: <RecordsLayout/>,
            children: [
              {
                path: 'records',
                element: <RecordsPage/>,
              }
            ],
          },
          {
            element: <DistrictLayout/>,
            children: [
              {
                path: 'districts',
                element: <DistrictsPage/>,
              }
            ],
          },
          {
            element: <ShutdownsLayout/>,
            children: [
              {
                path: 'shutdowns',
                element: <ShutdownsPage/>,
              }
            ],
          },
          {
            element: <FacilitiesLayout/>,
            children: [
              {
                path: 'facilities',
                element: <FacilitiesPage/>,
              }
            ],
          },
          {
            element: <PagesLayout/>,
            children: [
              {
                path: 'pages',
                element: <PagesPage/>,
              }
            ],
          },
          {
            element: <EmployeesLayout/>,
            children: [
              {
                path: 'users',
                element: <EmployeesPage/>,
              }
            ],
          },
          {
            path: 'home',
            element: <HomePage/>,
          },
        ],
      },
    ],
  },
]);

export default router;
