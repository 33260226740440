import React, {useState} from "react";
import ArticleDialog from "./components/article-dialog";
import SGrid from "../../components/grid/grid";
import {ArticleEntityT, useArticle} from "../../models/articles/articles-provider";
import clsx from "clsx";
import {Button} from "primereact/button";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";
import * as R from "ramda";
import {UndefT} from "../../definitions/common.defenitions";
import {useUser} from "../../models/user/user-provider";
import {PermissionsEnum} from "../../constants/permissions";

const ArticlesPage = () => {
  const [dialogIsVisible, dialogSetIsVisible] = useState(false);
  const [entity, setEntity] = useState<UndefT<ArticleEntityT>>(null!);
  const {
    isLoading,
    data = [],
    open,
    deleteArticle,
  } = useArticle();
  const {
    rights,
  } = useUser();

  const onRemove = (entity: ArticleEntityT) => {
    confirmDialog({
      message: 'Хотите удалить статью? Действие нельзя будет отменить!',
      header: 'Подтвердите удаление',
      acceptLabel: 'Да',
      rejectLabel: 'Отмена',
      icon: 'pi pi-info-circle',
      defaultFocus: 'reject',
      acceptClassName: 'p-button-danger',
      accept: () => deleteArticle(entity.id!),
    });
  }

  const onSelect = async (selected: ArticleEntityT) => {
    if (R.isNil(selected) || R.isNil(selected.id)) {
      return;
    }

    const entity = await open(selected.id);
    setEntity(entity);
    dialogSetIsVisible(true);
  }

  const headerElement = (
    <div
      className={'flex justify-content-between'}
    >
      <span>Статьи</span>
      <div
        className={'flex'}
      >
        <ArticleDialog
          entity={entity}
          dialogConfig={{
            dialogConfig: {
              visible: dialogIsVisible,
              // @ts-ignore
              onHide: () => {
                setEntity(null);
                dialogSetIsVisible(false);
              },
            },
            buttonConfig: {
              onClick: () => dialogSetIsVisible(true),
            },
          }}
        />
      </div>
    </div>
  );

  const verifiedBodyTemplate = (rowData: ArticleEntityT) => {
    return <i className={clsx('pi', { 'true-icon pi-check-circle': rowData.published, 'false-icon pi-times-circle': !rowData.published })}></i>;
  };

  return (
    <>
      <SGrid
        header={headerElement}
        selectionMode={'single'}
        value={data}
        loading={isLoading}
        onRowClick={({ data }) => onSelect(data as ArticleEntityT)}
        columns={[
          {
            headerStyle: { width: '1rem' },
          },
          {
            headerStyle: { width: '100px' },
            field: 'id',
            header: 'id #',
          },
          {
            field: 'title',
            header: 'Заголовок',
          },
          {
            headerStyle: { width: '150px' },
            field: 'type',
            header: 'Категория',
            body: (itm: ArticleEntityT) => <span>{itm.type.name}</span>,
          },
          {
            headerStyle: { width: '150px' },
            field: 'published',
            header: 'Опубликована',
            body: verifiedBodyTemplate,
            align: 'center',
          },
          {
            headerStyle: { width: '100px' },
            body: (data, options) => (
              <Button
                disabled={!rights?.includes(PermissionsEnum.ARTICLES_DELETE)}
                icon="pi pi-times"
                onClick={() => onRemove(data)}
                severity="danger"
                outlined
              />
            ),
          },
        ]}
      />
      <ConfirmDialog/>
    </>
  );
}

export default ArticlesPage;