import axios from './axios';

import {MessageContextT} from '../providers/message/message-provider';
import {UserContextT} from '../models/user/user-provider';

export type InterceptorsContext = {
  useMessage: MessageContextT;
  useUser: UserContextT;
}

const interceptors = {
  setupInterceptors: (context: InterceptorsContext) => {
    const {
      logout,
    } = context.useUser;

    axios.interceptors.response.use(function (response) {
      return response;
    }, function (error) {
      if ( error.response.status === 401) {
        logout();
      }
      return Promise.reject(error);
    });

  }
};

export default interceptors;
