import {Outlet} from "react-router-dom";

import ShutdownsProvider from "../../models/shutdowns/shutdowns-provider";
import DistrictsProvider from "../../models/districts/district-provider";
import FacilitiesProvider from "../../models/facilities/facilities-provider";

const ShutdownsLayout = () => {
  return (
    <DistrictsProvider>
      <FacilitiesProvider>
        <ShutdownsProvider>
          <Outlet/>
        </ShutdownsProvider>
      </FacilitiesProvider>
    </DistrictsProvider>
  );
}

export default ShutdownsLayout;