import SForm from "../../../components/form/form.component";
import STextInput from "../../../components/inputs/text-input/text-input";
import SDropdown from "../../../components/inputs/dropdown-input/drop-down-input";
import {SDialog} from "../../../components/dialog/SDialog";
import {useArticleTypes} from "../../../models/article-types/article-types-provider";
import * as yup from "yup";
import SSwitcherInput from "../../../components/inputs/switcher-input/switcher-input";
import {useUser} from "../../../models/user/user-provider";
import {PermissionsEnum} from "../../../constants/permissions";

const ArticleTypesDialog = () => {
  const {
    data = [],
    create,
  } = useArticleTypes();
  const {
    rights,
  } = useUser();

  return (
    <SDialog
      canAdd={rights?.includes(PermissionsEnum.ARTICLE_TYPES_SAVE)}
      dialogTitle={'Тип (Раздел)'}
      buttonLabel={'Новый тип'}
      dialogConfig={{
        style: { width: '50vh', },
      }}
    >
      <SForm
        disabled={!rights?.includes(PermissionsEnum.ARTICLE_TYPES_SAVE)}
        onSubmit={(data) => create(data)}
        defaultValues={{
          name: '',
          description: '',
          parentId: null,
          published: true,
          hidden: false,
        }}
        schema={yup.object({
          name: yup.string().required('Имя обязательно!'),
        })}
      >
        <STextInput
          label={'Имя'}
          name={'name'}
        />
        <STextInput
          label={'Описание'}
          name={'description'}
        />
        <SDropdown
          label={'Родительская категория'}
          name={'typeId'}
          showClear={true}
          options={data}
          optionLabel={'name'}
          optionValue={'id'}
        />
        <SSwitcherInput
          label={'Доступна'}
          name={'published'}
        />
        <SSwitcherInput
          label={'Скрыта'}
          name={'hidden'}
        />
      </SForm>
    </SDialog>
  );
}

export default ArticleTypesDialog;