import {Column, ColumnProps} from 'primereact/column';
import {TreeTable, TreeTableProps} from 'primereact/treetable';
import React, {FC, useMemo} from 'react';

export type STreeTableProps = TreeTableProps & {
  columns?: ColumnProps[];
};
const STreeTable: FC<STreeTableProps> = (props) => {
  const {
    columns = [],
  } = props;

  const columnElements = useMemo(() => {
    return columns.map((column, index) => (
      <Column
        key={column.field}
        expander={index === 0}
        {...column}
      />
    ));
  }, [columns]);

  return (
    <TreeTable
      stripedRows={true}
      tableStyle={{ minWidth: '100%' }}
      emptyMessage={'Данные отсутствуют'}
      {...props}
    >
      {columnElements}
    </TreeTable>
  )
}

export default STreeTable;
