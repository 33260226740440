import DistrictsProvider from "../../models/districts/district-provider";
import {Outlet} from "react-router-dom";

const DistrictLayout = () => {
  return (
    <DistrictsProvider>
      <Outlet/>
    </DistrictsProvider>
  );
}

export default DistrictLayout;